import { MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505, MsalBuilders_SilentRequestBuilder__Account_31E51057, MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D, MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505, MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0 } from "../../../Fable/Fable.Msal/Fable.Msal.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { PbsListMsg } from "./Types.js";
import { printf, toText } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { fetch$, Types_RequestProperties, Types_HttpRequestHeaders } from "../../../fable_modules/Fable.Fetch.2.2.0/Fetch.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.11/MapUtil.js";
import { SimpleJson_tryParse } from "../../../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_serialize, Convert_fromJson } from "../../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../../../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { CreatePbsTranslationPostParams$reflection, PbsToEdit$reflection, Pbs$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { class_type, option_type, union_type, string_type, tuple_type, list_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";

export function loginRedirect(pci) {
    const redirectRequest = MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0(MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505(MsalBuilders_redirectRequest), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.loginRedirect(redirectRequest).then(() => (Promise.resolve("OK")))))), void 0, (arg0) => (new PbsListMsg(1, arg0)), (arg0_1) => (new PbsListMsg(36, arg0_1)));
}

export function reqHeads(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

export function fetchPbses(pci, address, projectId) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1);
        return pci.acquireTokenSilent(silentRequest).then((_arg1) => (fetch$(address_1, reqHeads(_arg1.idToken)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection()))]], [["ErrorValue", string_type]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })))));
    })), projectId, (arg0) => (new PbsListMsg(4, arg0)), (arg0_1) => (new PbsListMsg(36, arg0_1)));
}

export function fetchTranslations(pci, address, projectId, languageId) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1, languageId);
        return pci.acquireTokenSilent(silentRequest).then((_arg1) => (fetch$(address_1, reqHeads(_arg1.idToken)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(tuple_type(Pbs$reflection(), option_type(Pbs$reflection()))), class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(tuple_type(Pbs$reflection(), option_type(Pbs$reflection()))), class_type("System.Guid"))]], [["ErrorValue", string_type]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })))));
    })), projectId, (arg0) => (new PbsListMsg(8, arg0)), (arg0_1) => (new PbsListMsg(36, arg0_1)));
}

export function pbsEditPostRequestProps(token, jsonObject) {
    let headers;
    const serializedObj = Convert_serialize(jsonObject, createTypeInfo(PbsToEdit$reflection()));
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function translationCreatePostRequestProps(token, jsonObject) {
    let headers;
    const serializedObj = Convert_serialize(jsonObject, createTypeInfo(CreatePbsTranslationPostParams$reflection()));
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function postCreatePbsTranslation(pci, address, createTranslationParams) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((createTranslationParams_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => {
        const address_1 = address();
        return fetch$(address_1, translationCreatePostRequestProps(_arg1.idToken, createTranslationParams_1)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(Pbs$reflection(), class_type("System.Guid"), class_type("System.Guid"), class_type("System.Guid")), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(Pbs$reflection(), class_type("System.Guid"), class_type("System.Guid"), class_type("System.Guid"))]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })))), createTranslationParams, (arg0) => (new PbsListMsg(32, arg0)), (exn) => (new PbsListMsg(29, createTranslationParams.ParentPbsId, `Error creating PBS translation ${exn.message}`)));
}

export function postUpdatePbs(pci, address, saveAndClose, pbsToUpdate) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => {
        const address_1 = address();
        return fetch$(address_1, pbsEditPostRequestProps(_arg1.idToken, pbsToUpdate_1)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })))), pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(28, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsTranslation(pci, address, saveAndClose, pbsToUpdate) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => {
        const address_1 = address();
        return fetch$(address_1, pbsEditPostRequestProps(_arg1.idToken, pbsToUpdate_1)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })))), pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(34, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsCustomerDescription(pci, address, pbsToUpdate) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => {
        const address_1 = address();
        return fetch$(address_1, pbsEditPostRequestProps(_arg1.idToken, pbsToUpdate_1)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })))), pbsToUpdate, (arg0) => (new PbsListMsg(18, arg0)), (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

export function postResetCustomerDescription(pci, address, pbsToUpdate) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => {
        const address_1 = address();
        return fetch$(address_1, pbsEditPostRequestProps(_arg1.idToken, pbsToUpdate_1)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })))), pbsToUpdate, (arg0) => (new PbsListMsg(18, arg0)), (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

