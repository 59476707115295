import { oneOf, str, stringParam, s, top, map } from "../fable_modules/Fable.Elmish.UrlParser.1.0.0-beta-1/parser.fs.js";
import { Msg, AppState, Route_PbsRouteFromParams, Route_ProjectRouteFromParams, Route_ProjectsRouteFromParams_2297AD2E, Route } from "./Types.js";
import { singleton, ofArray, collect } from "../fable_modules/fable-library.3.7.11/List.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { WordExportComponentMsg } from "../Aimtec.Components.React/Components/WordExport/Types.js";
import { PbsTreeMenuMsg } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { PbsListMsg } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.4.0.0-beta-6/navigation.fs.js";

export const pageParser = (() => {
    let parser, queryParser, parser_2, parseBefore, queryParser_2, parser_4, parseBefore_2, queryParser_4;
    const parsers = ofArray([map(new Route(0), top), map(Route_ProjectsRouteFromParams_2297AD2E, (parser = s("projects"), (queryParser = stringParam("selectedProject"), (state_1) => collect(queryParser, parser(state_1))))), map((arg00_1) => ((arg10) => Route_ProjectRouteFromParams(arg00_1, arg10)), (parser_2 = ((parseBefore = s("project"), (state_4) => collect(str, parseBefore(state_4)))), (queryParser_2 = stringParam("selectedPbs"), (state_6) => collect(queryParser_2, parser_2(state_6))))), map((arg00_2) => ((arg10_1) => Route_PbsRouteFromParams(arg00_2, arg10_1)), (parser_4 = ((parseBefore_2 = s("pbs"), (state_9) => collect(str, parseBefore_2(state_9)))), (queryParser_4 = stringParam("selectedPbs"), (state_11) => collect(queryParser_4, parser_4(state_11)))))]);
    return (state_13) => oneOf(parsers, state_13);
})();

export function urlUpdate(route, state) {
    let msg_8, tupledArg, msg_10, tupledArg_1, msg_16, tupledArg_2, msg_18, tupledArg_3;
    if (route != null) {
        const route_1 = route;
        switch (route_1.tag) {
            case 1: {
                const selectedProjectId = route_1.fields[0];
                if (selectedProjectId != null) {
                    const projectId = selectedProjectId;
                    return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([singleton((dispatch_1) => {
                        dispatch_1(new Msg(7, projectId));
                    }), singleton((dispatch_2) => {
                        dispatch_2(new Msg(16, new WordExportComponentMsg(0, projectId)));
                    })]))];
                }
                else {
                    const requestTargetConceptProjectInfoesCmd = singleton((dispatch) => {
                        dispatch(new Msg(7, void 0));
                    });
                    return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([requestTargetConceptProjectInfoesCmd, requestTargetConceptProjectInfoesCmd]))];
                }
            }
            case 2: {
                const selectedPbsId = route_1.fields[1];
                const projectId_1 = route_1.fields[0];
                return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([singleton((dispatch_3) => {
                    dispatch_3(new Msg(7, projectId_1));
                }), (msg_8 = (new Msg(17, (tupledArg = [projectId_1, selectedPbsId], new PbsTreeMenuMsg(2, tupledArg[0], tupledArg[1])))), singleton((dispatch_4) => {
                    dispatch_4(msg_8);
                })), (msg_10 = (new Msg(19, (tupledArg_1 = [projectId_1, selectedPbsId], new PbsListMsg(2, tupledArg_1[0], tupledArg_1[1])))), singleton((dispatch_5) => {
                    dispatch_5(msg_10);
                })), singleton((dispatch_6) => {
                    dispatch_6(new Msg(16, new WordExportComponentMsg(0, projectId_1)));
                })]))];
            }
            case 3: {
                const selectedPbsId_1 = route_1.fields[1];
                const pbsId = route_1.fields[0];
                return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([singleton((dispatch_7) => {
                    dispatch_7(new Msg(7, pbsId));
                }), (msg_16 = (new Msg(17, (tupledArg_2 = [pbsId, selectedPbsId_1], new PbsTreeMenuMsg(3, tupledArg_2[0], tupledArg_2[1])))), singleton((dispatch_8) => {
                    dispatch_8(msg_16);
                })), (msg_18 = (new Msg(19, (tupledArg_3 = [pbsId, selectedPbsId_1], new PbsListMsg(3, tupledArg_3[0], tupledArg_3[1])))), singleton((dispatch_9) => {
                    dispatch_9(msg_18);
                }))]))];
            }
            case 4: {
                return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
            }
            default: {
                return [new AppState(route_1, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Navigation_newUrl("#/projects")];
            }
        }
    }
    else {
        return [new AppState(new Route(4), state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
    }
}

