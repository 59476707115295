import { HeaderPropps, Render as Render_1 } from "../Aimtec.Components.React/Components/AimtecHeader/AimtecHeaderComponent.js";
import { AppConfig_appVersion } from "../Configuration.js";
import * as bootstrap$002Emin from "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as bootstrap_icons from "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import * as style from "../../../src/style.scss";
import { Msg } from "./Types.js";
import { createElement } from "react";
import * as react from "react";
import { Render as Render_2 } from "../Aimtec.Components.React/Components/PbsTreeMenu/View.js";
import { value as value_97 } from "../fable_modules/fable-library.3.7.11/Option.js";
import { WordExportComponentMsg } from "../Aimtec.Components.React/Components/WordExport/Types.js";
import { RenderPartialExport, Render as Render_3 } from "../Aimtec.Components.React/Components/WordExport/View.js";
import { ofArray, singleton, append, map, exists } from "../fable_modules/fable-library.3.7.11/List.js";
import { createObj } from "../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { Style, Render as Render_4 } from "../Aimtec.Components.React/Components/Preloader/Preloader.js";
import { Render as Render_5 } from "../Aimtec.Components.React/Components/D365ProjectFrame/ProjectViewD365.js";
import { parse } from "../fable_modules/fable-library.3.7.11/Guid.js";
import { toString } from "../fable_modules/fable-library.3.7.11/Types.js";
import { Render as Render_6 } from "./LanguageButtons.js";
import { Render as Render_7 } from "../Aimtec.Components.React/Components/PbsList/View.js";
import { Render as Render_8 } from "../Aimtec.Components.React/Components/ErrorPage/RouteNotFoundPage.js";

function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

export function headerComponent(projectRef, pbsRef, userInfo, authFn, logoFn, headerFn, projectFn) {
    return Render_1(new HeaderPropps(AppConfig_appVersion, projectRef, pbsRef, userInfo, authFn, logoFn, headerFn, projectFn));
}

export function Render(renderInputProps) {
    let elems_2, elems_1, elems_4, elems_3, elems_8, elems_6, elems_10, elems_16, elems_14, arg00_4, elems_15, matchValue_3, pbsState, elems_17;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const header = headerComponent(void 0, void 0, void 0, () => {
    }, () => {
        dispatch(new Msg(2));
    }, () => {
    }, (value_2) => {
    });
    let menuCmp;
    const matchValue = state.PbsTreeMenuState;
    if (matchValue == null) {
        menuCmp = createElement("h1", {
            children: ["Unititalised Menu Component"],
        });
    }
    else {
        const menuState = matchValue;
        menuCmp = createElement(Render_2, {
            state: menuState,
            dispatch: (arg) => {
                dispatch(new Msg(17, arg));
            },
            onExportChildren: (pbsId) => {
                let tupledArg;
                dispatch(new Msg(16, (tupledArg = [value_97(state.SelectedProjectInfo).SelectedProject.Id, pbsId, 0], new WordExportComponentMsg(4, tupledArg[0], tupledArg[1], tupledArg[2]))));
            },
        });
    }
    const wordExportCmp = (onFailedItemClick) => {
        const matchValue_1 = state.WordExportComponentState;
        if (matchValue_1 != null) {
            return Render_3(matchValue_1, (arg_1) => {
                dispatch(new Msg(16, arg_1));
            }, onFailedItemClick);
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    };
    const projectSelect = (defaultText, projectInfoes, selectedProject, navigateFn) => {
        let elems;
        let selectedValue;
        if (selectedProject == null) {
            selectedValue = "00000000-0000-0000-0000-000000000000";
        }
        else {
            const sp = selectedProject;
            selectedValue = (exists((p_1) => (p_1.Id === sp.SelectedProject.Id), projectInfoes) ? sp.SelectedProject.Id : "00000000-0000-0000-0000-000000000000");
        }
        const defaultDisabletOption = createElement("option", {
            children: defaultText,
            disabled: true,
            hidden: true,
            value: "00000000-0000-0000-0000-000000000000",
        });
        const options = map((pi) => createElement("option", {
            children: `${pi.Name} - (${pi.Account})`,
            value: pi.Id,
        }), projectInfoes);
        return createElement("select", createObj(ofArray([["value", selectedValue], ["className", "form-select"], ["onChange", navigateFn], (elems = append(options, singleton(defaultDisabletOption)), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    const matchValue_4 = state.CurrentRoute;
    let pattern_matching_result, projectId_3, selectedProcessId;
    switch (matchValue_4.tag) {
        case 1: {
            pattern_matching_result = 1;
            break;
        }
        case 2: {
            pattern_matching_result = 2;
            projectId_3 = matchValue_4.fields[0];
            selectedProcessId = matchValue_4.fields[1];
            break;
        }
        case 3: {
            pattern_matching_result = 2;
            projectId_3 = matchValue_4.fields[0];
            selectedProcessId = matchValue_4.fields[1];
            break;
        }
        case 4: {
            pattern_matching_result = 3;
            break;
        }
        default: pattern_matching_result = 0}
    switch (pattern_matching_result) {
        case 0: {
            return createElement("p", {
                children: ["Route.Root"],
            });
        }
        case 1: {
            const selectedProjectId = matchValue_4.fields[0];
            let projectViewOrTutorial;
            if (selectedProjectId == null) {
                const xs_9 = [createElement("br", {}), Render_4(new Style(0), "No project selected.")];
                projectViewOrTutorial = react.createElement(react.Fragment, {}, ...xs_9);
            }
            else {
                const crmProjectUrl = "https://aimtec.crm4.dynamics.com/main.aspx?forceUCI=1\u0026pagetype=entityrecord\u0026etn=new_project\u0026id=" + selectedProjectId;
                projectViewOrTutorial = createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", "alert alert-primary"], (elems_1 = [createElement("a", {
                    children: "If project page is not loaded please log in to D365 CRM first and then refresh this page.",
                    href: "https://aimtec.crm4.dynamics.com/",
                    target: "#blank",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(Render_5, {
                    src: crmProjectUrl,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
            }
            let navigateProjectButtonOrFragment;
            if (selectedProjectId != null) {
                const projectId_1 = selectedProjectId;
                navigateProjectButtonOrFragment = createElement("div", createObj(ofArray([["className", "row"], (elems_4 = [createElement("div", createObj(ofArray([["className", "btn-block btn-group"], (elems_3 = [createElement("button", {
                    className: "btn btn-primary",
                    children: "Edit Target Concept",
                    onClick: (_arg2) => {
                        dispatch(new Msg(4, projectId_1));
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
            }
            else {
                navigateProjectButtonOrFragment = react.createElement(react.Fragment, {});
            }
            let activeProjectsSelectOrFragment;
            const matchValue_5 = state.TargetConceptProjectInfoes;
            activeProjectsSelectOrFragment = ((matchValue_5 != null) ? projectSelect("Select from Active Projects", matchValue_5.ActiveProjects, state.SelectedProjectInfo, (e) => {
                dispatch(new Msg(4, parse(toString(op_Dynamic()(e.currentTarget)("value")))));
            }) : Render_4(new Style(0), "Loading"));
            let inactiveProjectsSelectOrFragment;
            const matchValue_6 = state.TargetConceptProjectInfoes;
            inactiveProjectsSelectOrFragment = ((matchValue_6 != null) ? projectSelect("Select From Inactive Projects", matchValue_6.InactiveProjects, state.SelectedProjectInfo, (e_1) => {
                dispatch(new Msg(4, parse(toString(op_Dynamic()(e_1.currentTarget)("value")))));
            }) : Render_4(new Style(0), "Loading"));
            let exportToWordOrFragment;
            if (selectedProjectId != null) {
                const projectId_2 = selectedProjectId;
                exportToWordOrFragment = wordExportCmp((id) => {
                    let tupledArg_1;
                    dispatch((tupledArg_1 = [projectId_2, id], new Msg(6, tupledArg_1[0], tupledArg_1[1])));
                });
            }
            else {
                exportToWordOrFragment = react.createElement(react.Fragment, {});
            }
            return createElement("div", createObj(ofArray([["className", "grid-container"], (elems_8 = [createElement("div", {
                className: "grid-nav",
                children: Interop_reactApi.Children.toArray([header]),
            }), createElement("div", createObj(ofArray([["className", "grid-menu"], (elems_6 = [exportToWordOrFragment, createElement("br", {}), activeProjectsSelectOrFragment, createElement("br", {}), inactiveProjectsSelectOrFragment, createElement("br", {}), navigateProjectButtonOrFragment], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", {
                className: "grid-content",
                children: Interop_reactApi.Children.toArray([projectViewOrTutorial]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
        }
        case 2: {
            let activeProjectsSelectOrFragment_1;
            const matchValue_7 = state.TargetConceptProjectInfoes;
            activeProjectsSelectOrFragment_1 = ((matchValue_7 != null) ? projectSelect("Select from Active Projects", matchValue_7.ActiveProjects, state.SelectedProjectInfo, (e_2) => {
                dispatch(new Msg(4, parse(toString(op_Dynamic()(e_2.currentTarget)("value")))));
            }) : Render_4(new Style(0), "Loading"));
            let inactiveProjectsSelectOrFragment_1;
            const matchValue_8 = state.TargetConceptProjectInfoes;
            inactiveProjectsSelectOrFragment_1 = ((matchValue_8 != null) ? projectSelect("Select From Inactive Projects", matchValue_8.InactiveProjects, state.SelectedProjectInfo, (e_3) => {
                dispatch(new Msg(4, parse(toString(op_Dynamic()(e_3.currentTarget)("value")))));
            }) : Render_4(new Style(0), "Loading"));
            const backToProjectPageButton = createElement("button", {
                className: "btn btn-secondary",
                children: "Go To Project Page",
                onClick: (_arg3) => {
                    dispatch(new Msg(3, projectId_3));
                },
            });
            const controlButtons = createElement("div", createObj(ofArray([["className", "row"], (elems_10 = [createElement("div", {
                className: "btn-group",
                children: Interop_reactApi.Children.toArray([backToProjectPageButton]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
            const languageButons = createElement(Render_6, {
                selectedProjectInfoO: state.SelectedProjectInfo,
                dispatch: dispatch,
            });
            const export$ = wordExportCmp((id_1) => {
                let tupledArg_2;
                dispatch((tupledArg_2 = [projectId_3, id_1], new Msg(5, tupledArg_2[0], tupledArg_2[1])));
            });
            return createElement("div", createObj(ofArray([["className", "grid-container"], (elems_16 = [createElement("div", {
                className: "grid-nav",
                children: Interop_reactApi.Children.toArray([header]),
            }), createElement("div", createObj(ofArray([["className", "grid-menu"], (elems_14 = [(arg00_4 = ofArray([export$, createElement("br", {}), activeProjectsSelectOrFragment_1, createElement("br", {}), inactiveProjectsSelectOrFragment_1, createElement("br", {}), controlButtons, languageButons]), createElement("div", {
                className: "menu-elements-container",
                children: Interop_reactApi.Children.toArray(Array.from(arg00_4)),
            })), createElement("hr", {}), createElement("div", {
                className: "menu-container",
                children: Interop_reactApi.Children.toArray([menuCmp]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "grid-content"], (elems_15 = [(matchValue_3 = state.PbsesListState, (matchValue_3 == null) ? Render_4(new Style(4), "Unitialised PBS List Component") : ((pbsState = matchValue_3, createElement(Render_7, {
                state: pbsState,
                dispatch: (arg_3) => {
                    dispatch(new Msg(19, arg_3));
                },
                onServiceDeskStart: void 0,
                exportComponent: (p) => {
                    const matchValue_2 = state.WordExportComponentState;
                    return (matchValue_2 != null) ? RenderPartialExport(matchValue_2, (arg_2) => {
                        dispatch(new Msg(16, arg_2));
                    }, p) : react.createElement(react.Fragment, {});
                },
            }))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])));
        }
        case 3: {
            const children_5 = ofArray([Render_8, createElement("div", createObj(ofArray([["className", "row"], (elems_17 = [createElement("button", {
                onClick: (_arg4) => {
                    dispatch(new Msg(2));
                },
                className: "btn btn-success",
                children: "Go to application home",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))]);
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            });
        }
    }
}

