import { MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505, MsalBuilders_SilentRequestBuilder__Account_31E51057, MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D, MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505, MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0 } from "../Fable/Fable.Msal/Fable.Msal.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.7.11/List.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { Msg } from "./Types.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.11/String.js";
import { fetch$, Types_RequestProperties, Types_HttpRequestHeaders } from "../fable_modules/Fable.Fetch.2.2.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.11/MapUtil.js";
import { SimpleJson_tryParse } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { TargetConceptProjectInfoes$reflection } from "../AIM/Aimtec.D365.Domain/Aimtec.D365.Project.Types.js";
import { union_type, string_type } from "../fable_modules/fable-library.3.7.11/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.11/Choice.js";

export function loginRedirect(pci) {
    const redirectRequest = MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0(MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505(MsalBuilders_redirectRequest), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.loginRedirect(redirectRequest).then(() => (Promise.resolve("OK")))))), void 0, (arg0) => (new Msg(1, arg0)), (arg0_1) => (new Msg(21, arg0_1)));
}

export function reqHeads(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

export function fetchProjectInfoes(pci, address, selectedProjectIdO) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.acquireTokenSilent(silentRequest).then((_arg1) => (fetch$(address, reqHeads(_arg1.idToken)).then((_arg2) => (_arg2.text().then((_arg3) => {
        let matchValue;
        return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [TargetConceptProjectInfoes$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", TargetConceptProjectInfoes$reflection()]], [["ErrorValue", string_type]]]))) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()));
    })))))))), void 0, (result) => {
        const tupledArg = [selectedProjectIdO, result];
        return new Msg(8, tupledArg[0], tupledArg[1]);
    }, (arg0_1) => (new Msg(21, arg0_1)));
}

