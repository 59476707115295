import * as style from "../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { map as map_1, isEmpty, find, ofArray, exists } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { FSharpChoice$7 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";
import { value as value_34, map, defaultArg } from "../../../fable_modules/fable-library.3.7.11/Option.js";
import { PbsListMsg } from "./Types.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { Render as Render_1, Props } from "./PbsWindow/PbsRead.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { Render as Render_2, Props as Props_1 } from "./PbsWindow/PbsEdit.js";
import { Render as Render_3, Props as Props_2 } from "./PbsWindow/PbsEditCustomer.js";
import { Render as Render_4 } from "./PbsWindow/PbsSaving.js";
import { Render as Render_5 } from "./PbsWindow/PbsError.js";
import { Render as Render_6 } from "../ErrorPage/ErrorPage.js";
import { Style, Render as Render_7 } from "../Preloader/Preloader.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

function pbsDepthClassName(pbs) {
    const matchValue = pbs.Level | 0;
    switch (matchValue) {
        case 1: {
            return "process-window-depth-1";
        }
        case 2: {
            return "process-window-depth-2";
        }
        case 3: {
            return "process-window-depth-3";
        }
        case 4: {
            return "process-window-depth-4";
        }
        default: {
            return "process-window-depth-5";
        }
    }
}

export function $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(state, pbsId) {
    if (exists((pbs) => (pbs.Id === pbsId), state.PbsesEdited)) {
        return new FSharpChoice$7(1, void 0);
    }
    else if (exists((pbs_1) => (pbs_1.Id === pbsId), state.CustomerDescriptionsEdited)) {
        return new FSharpChoice$7(2, void 0);
    }
    else if (exists((pbs_2) => (pbs_2.Id === pbsId), state.PbsesSaved)) {
        return new FSharpChoice$7(3, void 0);
    }
    else if (exists((pbs_3) => (pbs_3.Id === pbsId), state.CustomerDescriptionsSaved)) {
        return new FSharpChoice$7(4, void 0);
    }
    else if (exists((tupledArg) => (tupledArg[0].Id === pbsId), state.PbsesError)) {
        return new FSharpChoice$7(5, void 0);
    }
    else if (exists((tupledArg_1) => (tupledArg_1[0].Id === pbsId), state.CustomerDescriptionError)) {
        return new FSharpChoice$7(6, void 0);
    }
    else {
        return new FSharpChoice$7(0, void 0);
    }
}

export function Render(renderInputProps) {
    const exportComponent = renderInputProps.exportComponent;
    const onServiceDeskStart = renderInputProps.onServiceDeskStart;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const pbs_3 = (state_1, pbs, isTranslation) => {
        let elems, elems_1, elems_2;
        const matchValue = [state_1, pbs.Id];
        let pattern_matching_result;
        const activePatternResult26569 = $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(matchValue[0], matchValue[1]);
        if (activePatternResult26569.tag === 1) {
            pattern_matching_result = 1;
        }
        else if (activePatternResult26569.tag === 2) {
            pattern_matching_result = 2;
        }
        else if (activePatternResult26569.tag === 3) {
            pattern_matching_result = 3;
        }
        else if (activePatternResult26569.tag === 4) {
            pattern_matching_result = 3;
        }
        else if (activePatternResult26569.tag === 5) {
            pattern_matching_result = 4;
        }
        else if (activePatternResult26569.tag === 6) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                const props = new Props(pbs, defaultArg(map((spi) => (spi.Id === pbs.Id), state_1.SelectedPbs), false), state_1.AccessToFunctions, (_arg2) => {
                    dispatch(new PbsListMsg(22, pbs.Id));
                }, (_arg3) => {
                    dispatch(new PbsListMsg(14, pbs.Id));
                }, onServiceDeskStart, (_arg1) => {
                    let tupledArg;
                    dispatch((tupledArg = [new XrmEntityReference(value_34(state_1.SelectedProjectId).Id, pbs.Name), new XrmEntityReference(pbs.Id, pbs.Name)], new PbsListMsg(5, tupledArg[0], tupledArg[1])));
                });
                return createElement("div", createObj(ofArray([["className", isTranslation ? "process-window" : (`process-window ${pbsDepthClassName(pbs)}`)], (elems = [Render_1(props, exportComponent)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
            }
            case 1: {
                const editPbsOrTraFn = isTranslation ? ((tupledArg_3) => {
                    let tupledArg_4;
                    dispatch((tupledArg_4 = [tupledArg_3[0], tupledArg_3[1]], new PbsListMsg(33, tupledArg_4[0], tupledArg_4[1])));
                }) : ((tupledArg_1) => {
                    let tupledArg_2;
                    dispatch((tupledArg_2 = [tupledArg_1[0], tupledArg_1[1]], new PbsListMsg(27, tupledArg_2[0], tupledArg_2[1])));
                });
                const pbsEdited = find((p) => (p.Id === pbs.Id), state_1.PbsesEdited);
                const props_2 = new Props_1(pbsEdited.Id, pbsEdited.Name, pbsEdited.Description, (tupledArg_5) => {
                    let tupledArg_6;
                    dispatch((tupledArg_6 = [tupledArg_5[0], tupledArg_5[1]], new PbsListMsg(24, tupledArg_6[0], tupledArg_6[1])));
                }, (tupledArg_7) => {
                    let tupledArg_8;
                    dispatch((tupledArg_8 = [tupledArg_7[0], tupledArg_7[1]], new PbsListMsg(25, tupledArg_8[0], tupledArg_8[1])));
                }, (id_5) => {
                    dispatch(new PbsListMsg(23, id_5));
                }, editPbsOrTraFn, isTranslation, (tupledArg_9) => {
                    let tupledArg_10;
                    dispatch((tupledArg_10 = [value_34(state_1.SelectedProjectId).Id, tupledArg_9[0], tupledArg_9[1]], new PbsListMsg(31, tupledArg_10[0], tupledArg_10[1], tupledArg_10[2])));
                });
                return createElement("div", createObj(ofArray([["className", isTranslation ? "process-window" : (`process-window ${pbsDepthClassName(pbs)}`)], (elems_1 = [createElement(Render_2, props_2)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            }
            case 2: {
                const props_5 = new Props_2(pbs.Id, pbs.Name, pbs.Description, defaultArg(map((dc) => dc, pbs.DescriptionCustomer), " "), (id_6) => {
                    dispatch(new PbsListMsg(15, id_6));
                }, (id_7) => {
                    dispatch(new PbsListMsg(17, id_7));
                }, (id_8) => {
                    dispatch(new PbsListMsg(19, id_8));
                }, (tupledArg_11) => {
                    let tupledArg_12;
                    dispatch((tupledArg_12 = [tupledArg_11[0], tupledArg_11[1]], new PbsListMsg(16, tupledArg_12[0], tupledArg_12[1])));
                });
                return createElement("div", createObj(ofArray([["className", isTranslation ? "process-window" : (`process-window ${pbsDepthClassName(pbs)}`)], (elems_2 = [createElement(Render_3, props_5)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
            }
            case 3: {
                return createElement("div", {
                    className: isTranslation ? "process-window" : (`process-window ${pbsDepthClassName(pbs)}`),
                    children: Interop_reactApi.Children.toArray([Render_4]),
                });
            }
            case 4: {
                const patternInput = find((tupledArg_13) => (tupledArg_13[0].Id === pbs.Id), state_1.PbsesError);
                return createElement(Render_5, {
                    errorMessage: patternInput[1],
                    onResetError: () => {
                        dispatch(new PbsListMsg(30, patternInput[0].Id));
                    },
                });
            }
            case 5: {
                const patternInput_1 = find((tupledArg_14) => (tupledArg_14[0].Id === pbs.Id), state_1.PbsesError);
                return createElement(Render_5, {
                    errorMessage: patternInput_1[1],
                    onResetError: () => {
                        dispatch(new PbsListMsg(30, patternInput_1[0].Id));
                    },
                });
            }
        }
    };
    const matchValue_1 = state.ComponentState;
    switch (matchValue_1.tag) {
        case 2: {
            const xs_4 = [Render_6(matchValue_1.fields[0], void 0)];
            return react.createElement(react.Fragment, {}, ...xs_4);
        }
        case 0: {
            const pbsesOrFilteredPbses = (!isEmpty(state.FilteredPbses)) ? state.FilteredPbses : state.Pbses;
            const pbsesOrTranslations = (state.SelectedLanguage != null) ? map_1((tupledArg_15) => {
                let elems_4, elems_7, elems_5;
                const p_3 = tupledArg_15[0];
                const t_1 = tupledArg_15[1];
                const translationSomeOrCreateWindow = (t_1 == null) ? createElement("div", createObj(ofArray([["className", "row"], (elems_4 = [createElement("button", {
                    className: "btn btn-primary",
                    onClick: (_arg6) => {
                        let tupledArg_16;
                        dispatch((tupledArg_16 = [value_34(state.SelectedProjectId).Id, p_3.Id, value_34(state.SelectedLanguage).Id], new PbsListMsg(31, tupledArg_16[0], tupledArg_16[1], tupledArg_16[2])));
                    },
                    children: "Create Translation",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))) : pbs_3(state, t_1, true);
                return createElement("div", createObj(ofArray([["className", "row"], (elems_7 = [createElement("div", createObj(ofArray([["className", "col-6"], (elems_5 = [pbs_3(state, p_3, false)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", {
                    className: "col-6",
                    children: Interop_reactApi.Children.toArray([translationSomeOrCreateWindow]),
                }), createElement("hr", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
            }, pbsesOrFilteredPbses) : map_1((tupledArg_17) => pbs_3(state, tupledArg_17[0], false), pbsesOrFilteredPbses);
            return createElement("div", {
                className: "pbs-list-container",
                children: Interop_reactApi.Children.toArray(Array.from(pbsesOrTranslations)),
            });
        }
        default: {
            return Render_7(new Style(0), "Loading");
        }
    }
}

