import { printf, toText } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { fetch$, Types_RequestProperties, Types_HttpRequestHeaders } from "../../../fable_modules/Fable.Fetch.2.2.0/Fetch.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.11/MapUtil.js";
import { Convert_fromJson, Convert_serialize } from "../../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../../../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { ExportInfo$reflection, ExportPostParams$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.WordExport.js";
import { MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505, MsalBuilders_SilentRequestBuilder__Account_31E51057, MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D } from "../../../Fable/Fable.Msal/Fable.Msal.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { SimpleJson_tryParse } from "../../../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { union_type, string_type, class_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";
import { WordExportComponentMsg } from "./Types.js";
import { Auto_generateBoxedEncoder_Z20B7B430, toString } from "../../../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";

function reqHeads(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

function postRequestProps(token, jsonObject) {
    let headers;
    const serializedObj = Convert_serialize(jsonObject, createTypeInfo(ExportPostParams$reflection()));
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function fetchProjectExportInfo(pci, address, projectId) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either((projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1);
        return pci.acquireTokenSilent(silentRequest).then((_arg1) => (fetch$(address_1, reqHeads(_arg1.idToken)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })))));
    })), projectId, (arg0) => (new WordExportComponentMsg(1, arg0)), (arg0_1) => (new WordExportComponentMsg(7, arg0_1)));
}

export function wordExportPostRequestProps(token, jsonObject) {
    let headers;
    const serializedObj = toString(4, Auto_generateBoxedEncoder_Z20B7B430(ExportPostParams$reflection(), void 0, void 0, void 0)(jsonObject));
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function postWordExport(pci, address, wordExportPostParams) {
    const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return pci.acquireTokenSilent(silentRequest).then((_arg1) => (fetch$(address_1, wordExportPostRequestProps(_arg1.idToken, wordExportPostParams)).then((_arg2) => (_arg2.text().then((_arg3) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg3), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ExportInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ExportInfo$reflection()]], [["ErrorValue", string_type]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })))));
    })), void 0, (arg0) => (new WordExportComponentMsg(5, arg0)), (arg0_1) => (new WordExportComponentMsg(7, arg0_1)));
}

