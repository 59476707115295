import * as style from "../../../../../../Aimtec.Components.React/Components/WordExport/style.scss";
import { Style, Render as Render_1 } from "../Preloader/Preloader.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { join } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { WordExportComponentMsg } from "./Types.js";
import { map, isEmpty, ofArray, singleton } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { parse } from "../../../fable_modules/fable-library.3.7.11/Guid.js";
import { Render as Render_2 } from "../ErrorPage/ErrorPage.js";


export function Render(state, dispatch, onFailedItemClick) {
    let elems_2, elems_1, elems, children, children_2, children_4, children_6, elems_6, elems_5, exceptions, children_9, elems_4;
    const preloader = Render_1(new Style(0), "Exporting");
    const exportDropdown = createElement("div", createObj(ofArray([["className", "row"], (elems_2 = [createElement("div", createObj(ofArray([["className", "dropdown btn-block btn-group"], (elems_1 = [createElement("button", {
        className: "btn btn-primary dropdown-toggle",
        type: "button",
        id: "exportButton",
        ["data-bs-toggle"]: "dropdown",
        ["aria-expanded"]: false,
        children: "Export To Word",
    }), createElement("ul", createObj(ofArray([["className", "dropdown-menu"], ["aria-labelledby", join(" ", ["exportButton"])], (elems = [(children = singleton(createElement("a", {
        className: "dropdown-item",
        onClick: (_arg1) => {
            let tupledArg;
            dispatch((tupledArg = [state.SelectedProjectId, void 0, 0], new WordExportComponentMsg(4, tupledArg[0], tupledArg[1], tupledArg[2])));
        },
        children: "Standard",
    })), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton(createElement("a", {
        className: "dropdown-item",
        children: "Role \u0026 Module",
        onClick: (_arg2) => {
            let tupledArg_1;
            dispatch((tupledArg_1 = [state.SelectedProjectId, void 0, 1], new WordExportComponentMsg(4, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2])));
        },
    })), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = singleton(createElement("a", {
        className: "dropdown-item",
        children: "Key User \u0026 Consultant",
        onClick: (_arg3) => {
            let tupledArg_2;
            dispatch((tupledArg_2 = [state.SelectedProjectId, void 0, 2], new WordExportComponentMsg(4, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
        },
    })), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton(createElement("a", {
        className: "dropdown-item",
        children: "Klíčový uživatel \u0026 konzultant",
        onClick: (_arg4) => {
            let tupledArg_3;
            dispatch((tupledArg_3 = [state.SelectedProjectId, void 0, 3], new WordExportComponentMsg(4, tupledArg_3[0], tupledArg_3[1], tupledArg_3[2])));
        },
    })), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    let downloadButtonOrError;
    const matchValue = state.ExportInfo;
    if (matchValue == null) {
        downloadButtonOrError = createElement("div", {
            className: "alert alert-info",
            children: "Error - Word Document might not been generated on server. Please contact support.",
        });
    }
    else {
        const exportStamp = parse(matchValue.ExportStamp);
        const children_11 = ofArray([Render_1(new Style(2), "Export Finished"), createElement("div", createObj(ofArray([["className", "row"], (elems_6 = [createElement("div", createObj(ofArray([["className", "btn-block btn-group"], (elems_5 = [createElement("a", {
            className: "btn btn-secondary",
            children: "Download Document",
            href: state.WordExportRestAddresses.DownloadWordAddress(exportStamp),
        }), createElement("a", {
            className: "btn btn-secondary",
            children: "Clear Export Data",
            onClick: (_arg6) => {
                dispatch(new WordExportComponentMsg(6));
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))]);
        downloadButtonOrError = createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_11)),
        });
    }
    const matchValue_1 = state.ComponentState;
    switch (matchValue_1.tag) {
        case 1: {
            return createElement("div", {
                className: "",
                children: Interop_reactApi.Children.toArray([preloader]),
            });
        }
        case 0: {
            const matchValue_2 = state.ExportInfo;
            if (matchValue_2 != null) {
                const xs_22 = [exportDropdown, (exceptions = matchValue_2.Exceptions, (!isEmpty(exceptions)) ? ((children_9 = ofArray([createElement("div", {
                    className: "alert alert-warning",
                    children: "Some of processes failed to export:",
                }), createElement("div", createObj(singleton((elems_4 = map((tupledArg_4) => {
                    let elems_3;
                    return createElement("div", createObj(ofArray([["className", "alert alert-danger"], (elems_3 = [createElement("span", {
                        className: "export-error-link",
                        onClick: (_arg5) => {
                            onFailedItemClick(parse(tupledArg_4[2]));
                        },
                        children: `${tupledArg_4[0]}`,
                    }), createElement("span", {
                        children: `Error reason: ${tupledArg_4[1]}`,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
                }, exceptions), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])))), createElement("div", {
                    className: "alert alert-warning",
                    children: "You can download incomplete document",
                })]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_9)),
                }))) : react.createElement(react.Fragment, {})), createElement("div", {
                    className: "row",
                    children: Interop_reactApi.Children.toArray([downloadButtonOrError]),
                })];
                return react.createElement(react.Fragment, {}, ...xs_22);
            }
            else {
                return exportDropdown;
            }
        }
        default: {
            return Render_2(matchValue_1.fields[0], void 0);
        }
    }
}

export function RenderPartialExport(state, dispatch, pbsId) {
    let elems_1, elems, children, children_2, children_4, children_6;
    if (state.ComponentState.tag === 0) {
        return createElement("div", createObj(ofArray([["className", "dropdown btn-block btn-group"], (elems_1 = [createElement("button", {
            className: "btn btn-primary dropdown-toggle",
            type: "button",
            id: "exportButton",
            ["data-bs-toggle"]: "dropdown",
            ["aria-expanded"]: false,
            children: "Export",
        }), createElement("ul", createObj(ofArray([["className", "dropdown-menu"], ["aria-labelledby", join(" ", ["exportButton"])], (elems = [(children = singleton(createElement("a", {
            className: "dropdown-item",
            onClick: (_arg1) => {
                let tupledArg;
                dispatch((tupledArg = [state.SelectedProjectId, pbsId, 0], new WordExportComponentMsg(4, tupledArg[0], tupledArg[1], tupledArg[2])));
            },
            children: "Standard",
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_2 = singleton(createElement("a", {
            className: "dropdown-item",
            children: "Role \u0026 Module",
            onClick: (_arg2) => {
                let tupledArg_1;
                dispatch((tupledArg_1 = [state.SelectedProjectId, pbsId, 1], new WordExportComponentMsg(4, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2])));
            },
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_4 = singleton(createElement("a", {
            className: "dropdown-item",
            children: "Key User \u0026 Consultant",
            onClick: (_arg3) => {
                let tupledArg_2;
                dispatch((tupledArg_2 = [state.SelectedProjectId, pbsId, 2], new WordExportComponentMsg(4, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
            },
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = singleton(createElement("a", {
            className: "dropdown-item",
            children: "Klíčový uživatel \u0026 konzultant",
            onClick: (_arg4) => {
                let tupledArg_3;
                dispatch((tupledArg_3 = [state.SelectedProjectId, pbsId, 3], new WordExportComponentMsg(4, tupledArg_3[0], tupledArg_3[1], tupledArg_3[2])));
            },
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return createElement("a", {
            className: "btn btn-primary disabled",
            children: "Exporting",
        });
    }
}

