import * as style from "../../../../../../Aimtec.Components.React/Components/ErrorPage/style.scss";
import { ofArray, map } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { printf, toText } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";


export function Render(errorMessages, onResetError) {
    let errors;
    const xs_2 = map((em) => {
        let elems;
        return createElement("div", createObj(ofArray([["className", "alert alert-danger"], (elems = [createElement("label", {
            children: toText(printf("%s"))(em),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, errorMessages);
    errors = react.createElement(react.Fragment, {}, ...xs_2);
    let resetErrorButton;
    if (onResetError == null) {
        resetErrorButton = react.createElement(react.Fragment, {});
    }
    else {
        const fn = onResetError;
        resetErrorButton = createElement("button", {
            className: "btn btn-primary",
            children: "Go Back",
            onClick: (_arg1) => {
                fn();
            },
        });
    }
    return createElement("div", {
        className: "",
        children: Interop_reactApi.Children.toArray([resetErrorButton, errors]),
    });
}

