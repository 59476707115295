import { fetch$, Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.2.0/Fetch.fs.js";
import { singleton } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { Cmd_none, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { SimpleJson_tryParse } from "../../../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_fromJson } from "../../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../../../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { OnlineDocumentation$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import { union_type, string_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";
import { PbsTreeMenuMsg } from "./Types.js";

export const requestProps = singleton(new Types_RequestProperties(4, "include"));

export function fetchProject(address, projectId) {
    return Cmd_OfPromise_either((projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(address(projectId_1), requestProps).then((_arg1) => (_arg1.text().then((_arg2) => {
        let matchValue;
        return Promise.resolve((matchValue = SimpleJson_tryParse(_arg2), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentation$reflection()]], [["ErrorValue", string_type]]]))) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()));
    })))))), projectId, (arg0) => (new PbsTreeMenuMsg(4, arg0)), (arg0_1) => (new PbsTreeMenuMsg(9, arg0_1)));
}

export function fetchPbsChildren(address, projectId) {
    return Cmd_none();
}

