import { urlUpdate } from "./Router.js";
import { Msg, SelectedProjectInfo as SelectedProjectInfo_3, AppState, AppState_get_Empty } from "./Types.js";
import { Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { Navigation_modifyUrl, Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.4.0.0-beta-6/navigation.fs.js";
import { ComponentState as ComponentState_5 } from "../Aimtec.Components.React/Types.js";
import { loginRedirect, fetchProjectInfoes } from "./Rest.js";
import { PbsListApiAddress_postResetPbsCustomerDescriptionAddress, PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress, PbsListApiAddress_postUpdatePbsTranslationAddress, PbsListApiAddress_postCreatePbsTranslationAddress, PbsListApiAddress_postUpdatePbsAddress, PbsListApiAddress_fetchPbsTranslationsAddress, PbsListApiAddress_fetchPbsPbsesAddress, PbsListApiAddress_fetchPbsesAddress, PbsMenuApiAddress_fetchPbsChildrenAddress, PbsMenuApiAddress_fetchProjectAddress, WordExportApiAddress_fetchProjectExportInfo, WordExportApiAddress_downloadExportAddress, WordExportApiAddress_fetchExportAddress, ProjectApiAddress_fetchProjectInfoesAddress } from "../Configuration.js";
import { equals } from "../fable_modules/fable-library.3.7.11/Util.js";
import { option as option_7, OptionBuilder__Return_1505, OptionBuilder__Bind_Z424BC9ED, OptionBuilder__Delay_FCFD9EF, OptionBuilder__Run_FCFD9EF } from "../fable_modules/FsToolkit.ErrorHandling.2.0.0/OptionCE.fs.js";
import { ofArray, find, singleton, empty, append, tryFind } from "../fable_modules/fable-library.3.7.11/List.js";
import { PbsListRestAddresses, AccessToFunctions as AccessToFunctions_1, PbsListComponentState, PbsListMsg } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { WordExportComponentState as WordExportComponentState_4, WordExportRestAddresses, WordExportComponentMsg } from "../Aimtec.Components.React/Components/WordExport/Types.js";
import { map } from "../fable_modules/fable-library.3.7.11/Option.js";
import { PbsTreeMenuMsg, PbsMenuRestAddresses as PbsMenuRestAddresses_1, PbsTreeMenuState as PbsTreeMenuState_4 } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { update as update_1 } from "../Aimtec.Components.React/Components/WordExport/State.js";
import { update as update_2 } from "../Aimtec.Components.React/Components/PbsTreeMenu/State.js";
import { update as update_3 } from "../Aimtec.Components.React/Components/PbsList/State.js";

export function init(result) {
    const patternInput = urlUpdate(result, AppState_get_Empty());
    return [patternInput[0], patternInput[1]];
}

export function update(pci, msg, state) {
    let msg_1, msg_7, msg_3, tupledArg, extraMsg;
    switch (msg.tag) {
        case 1: {
            return [state, Cmd_none()];
        }
        case 2: {
            return [state, Navigation_newUrl("#projects")];
        }
        case 3: {
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, void 0, void 0, void 0), Navigation_newUrl(`#projects?selectedProject=${msg.fields[0]}`)];
        }
        case 4: {
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, void 0, void 0, void 0), Navigation_newUrl(`#project/${msg.fields[0]}`)];
        }
        case 7: {
            return [new AppState(state.CurrentRoute, new ComponentState_5(1), state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), fetchProjectInfoes(pci, ProjectApiAddress_fetchProjectInfoesAddress(), msg.fields[0])];
        }
        case 5: {
            const pbsId = msg.fields[1];
            const element = document.getElementById(pbsId);
            if (!equals(element, null)) {
                window.scroll(0, element.offsetTop - 0);
            }
            return [state, Navigation_modifyUrl(`#/project/${msg.fields[0]}?selectedPbs=${pbsId}`)];
        }
        case 6: {
            return [state, Navigation_newUrl(`#/project/${msg.fields[0]}?selectedPbs=${msg.fields[1]}`)];
        }
        case 9: {
            return [state, Navigation_modifyUrl(`#/project/${msg.fields[0].Id}?selectedPbs=${msg.fields[1].Id}`)];
        }
        case 8: {
            const result = msg.fields[1];
            if (result.tag === 0) {
                const projectInfoes = result.fields[0];
                return [new AppState(state.CurrentRoute, new ComponentState_5(0), projectInfoes, OptionBuilder__Run_FCFD9EF(option_7, OptionBuilder__Delay_FCFD9EF(option_7, () => OptionBuilder__Bind_Z424BC9ED(option_7, msg.fields[0], (_arg1) => OptionBuilder__Bind_Z424BC9ED(option_7, tryFind((pi) => (pi.Id === _arg1), append(projectInfoes.ActiveProjects, projectInfoes.InactiveProjects)), (_arg2) => {
                    let matchValue, spi;
                    const selectedProject = _arg2;
                    return OptionBuilder__Return_1505(option_7, (matchValue = state.SelectedProjectInfo, (matchValue == null) ? (new SelectedProjectInfo_3(selectedProject, empty(), void 0)) : ((spi = matchValue, new SelectedProjectInfo_3(selectedProject, spi.ProjectLanguages, spi.SelectedLanguageId)))));
                })))), state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
            }
            else {
                return [state, (msg_1 = (new Msg(21, new Error(result.fields[0]))), singleton((dispatch) => {
                    dispatch(msg_1);
                }))];
            }
        }
        case 13: {
            const projectRef_1 = msg.fields[0];
            const languages = msg.fields[1];
            const matchValue_1 = state.TargetConceptProjectInfoes;
            if (matchValue_1 != null) {
                const tcpi = matchValue_1;
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, new SelectedProjectInfo_3(find((pi_1) => (pi_1.Id === projectRef_1.Id), append(tcpi.ActiveProjects, tcpi.InactiveProjects)), languages, void 0), state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
            }
            else {
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, new SelectedProjectInfo_3(projectRef_1, languages, void 0), state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
            }
        }
        case 14: {
            const languageId = msg.fields[0];
            const matchValue_2 = state.SelectedProjectInfo;
            if (matchValue_2 == null) {
                return [state, (msg_7 = (new Msg(21, new Error("Frontend error: select language - SelectedProjectInfo is None"))), singleton((dispatch_3) => {
                    dispatch_3(msg_7);
                }))];
            }
            else {
                const pri = matchValue_2;
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, new SelectedProjectInfo_3(pri.SelectedProject, pri.ProjectLanguages, languageId), state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([Cmd_map((arg0_2) => (new Msg(19, arg0_2)), (msg_3 = ((tupledArg = [pri.SelectedProject.Id, languageId], new PbsListMsg(7, tupledArg[0], tupledArg[1]))), singleton((dispatch_1) => {
                    dispatch_1(msg_3);
                }))), singleton((dispatch_2) => {
                    dispatch_2(new Msg(16, new WordExportComponentMsg(2, languageId)));
                })]))];
            }
        }
        case 15: {
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, map((pri_1) => (new SelectedProjectInfo_3(pri_1.SelectedProject, pri_1.ProjectLanguages, void 0)), state.SelectedProjectInfo), state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_batch(ofArray([Cmd_map((arg0_6) => (new Msg(19, arg0_6)), singleton((dispatch_4) => {
                dispatch_4(new PbsListMsg(9));
            })), singleton((dispatch_5) => {
                dispatch_5(new Msg(16, new WordExportComponentMsg(3)));
            })]))];
        }
        case 10: {
            const serviceReference = msg.fields[0];
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, map((s) => (new PbsTreeMenuState_4(s.ComponentState, s.PbsMenuRestAddresses, s.OnlineDocumentation, s.SelectedProjectId, s.SelectedPbsId, serviceReference, s.SelectedServiceModule, s.ExpandedParentPbsesId, s.FilteredMenu)), state.PbsTreeMenuState), map((s_1) => (new PbsListComponentState(s_1.RestAddresses, s_1.AccessToFunctions, s_1.ComponentState, s_1.Pbses, s_1.SelectedPbs, s_1.SelectedProjectId, serviceReference, s_1.SelectedServiceModule, s_1.SelectedLanguage, s_1.TranslationLanguages, s_1.TranslationSelectedLanguage, s_1.PbsesEdited, s_1.PbsesSaved, s_1.PbsesError, s_1.FilteredPbses, s_1.CustomerDescriptionsEdited, s_1.CustomerDescriptionsSaved, s_1.CustomerDescriptionError)), state.PbsesListState)), empty()];
        }
        case 11: {
            const serviceModuleReference = msg.fields[0];
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, map((s_2) => (new PbsTreeMenuState_4(s_2.ComponentState, s_2.PbsMenuRestAddresses, s_2.OnlineDocumentation, s_2.SelectedProjectId, s_2.SelectedPbsId, s_2.SelectedService, serviceModuleReference, s_2.ExpandedParentPbsesId, s_2.FilteredMenu)), state.PbsTreeMenuState), map((s_3) => (new PbsListComponentState(s_3.RestAddresses, s_3.AccessToFunctions, s_3.ComponentState, s_3.Pbses, s_3.SelectedPbs, s_3.SelectedProjectId, s_3.SelectedService, serviceModuleReference, s_3.SelectedLanguage, s_3.TranslationLanguages, s_3.TranslationSelectedLanguage, s_3.PbsesEdited, s_3.PbsesSaved, s_3.PbsesError, s_3.FilteredPbses, s_3.CustomerDescriptionsEdited, s_3.CustomerDescriptionsSaved, s_3.CustomerDescriptionError)), state.PbsesListState)), empty()];
        }
        case 12: {
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, map((s_4) => (new PbsTreeMenuState_4(s_4.ComponentState, s_4.PbsMenuRestAddresses, s_4.OnlineDocumentation, s_4.SelectedProjectId, s_4.SelectedPbsId, void 0, void 0, s_4.ExpandedParentPbsesId, void 0)), state.PbsTreeMenuState), map((s_5) => (new PbsListComponentState(s_5.RestAddresses, s_5.AccessToFunctions, s_5.ComponentState, s_5.Pbses, s_5.SelectedPbs, s_5.SelectedProjectId, void 0, void 0, s_5.SelectedLanguage, s_5.TranslationLanguages, s_5.TranslationSelectedLanguage, s_5.PbsesEdited, s_5.PbsesSaved, s_5.PbsesError, empty(), s_5.CustomerDescriptionsEdited, s_5.CustomerDescriptionsSaved, s_5.CustomerDescriptionError)), state.PbsesListState)), empty()];
        }
        case 16: {
            const exportMsg = msg.fields[0];
            const matchValue_3 = state.WordExportComponentState;
            if (matchValue_3 == null) {
                const patternInput_1 = update_1(pci, exportMsg, new WordExportComponentState_4(new ComponentState_5(0), new WordExportRestAddresses(WordExportApiAddress_fetchExportAddress, WordExportApiAddress_downloadExportAddress, WordExportApiAddress_fetchProjectExportInfo), "00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", void 0, void 0, void 0));
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, patternInput_1[0], state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0_9) => (new Msg(16, arg0_9)), patternInput_1[1])];
            }
            else {
                const patternInput = update_1(pci, exportMsg, matchValue_3);
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, patternInput[0], state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0_8) => (new Msg(16, arg0_8)), patternInput[1])];
            }
        }
        case 17: {
            const menuMsg = msg.fields[0];
            const extraMessage = (pbsTreeMenuExtraCmd) => {
                let msg_13, tupledArg_1, msg_15, tupledArg_2, msg_23, tupledArg_3;
                switch (pbsTreeMenuExtraCmd.tag) {
                    case 1: {
                        const projectReference = pbsTreeMenuExtraCmd.fields[0];
                        const pbsReference = pbsTreeMenuExtraCmd.fields[1];
                        return Cmd_batch(ofArray([Cmd_map((arg0_10) => (new Msg(19, arg0_10)), (msg_13 = ((tupledArg_1 = [projectReference, pbsReference], new PbsListMsg(5, tupledArg_1[0], tupledArg_1[1]))), singleton((dispatch_6) => {
                            dispatch_6(msg_13);
                        }))), (msg_15 = ((tupledArg_2 = [projectReference, pbsReference], new Msg(9, tupledArg_2[0], tupledArg_2[1]))), singleton((dispatch_7) => {
                            dispatch_7(msg_15);
                        }))]));
                    }
                    case 2: {
                        return Cmd_map((arg0_13) => (new Msg(19, arg0_13)), singleton((dispatch_8) => {
                            dispatch_8(new PbsListMsg(11, pbsTreeMenuExtraCmd.fields[0]));
                        }));
                    }
                    case 3: {
                        const serviceModuleReference_1 = pbsTreeMenuExtraCmd.fields[0];
                        return Cmd_batch(ofArray([Cmd_map((arg0_15) => (new Msg(19, arg0_15)), singleton((dispatch_9) => {
                            dispatch_9(new PbsListMsg(12, serviceModuleReference_1));
                        })), singleton((dispatch_10) => {
                            dispatch_10(new Msg(11, serviceModuleReference_1));
                        })]));
                    }
                    case 4: {
                        const onlineDocumentation = pbsTreeMenuExtraCmd.fields[0];
                        return Cmd_batch(ofArray([(msg_23 = ((tupledArg_3 = [onlineDocumentation.Project, onlineDocumentation.Languages], new Msg(13, tupledArg_3[0], tupledArg_3[1]))), singleton((dispatch_11) => {
                            dispatch_11(msg_23);
                        })), Cmd_map((arg0_18) => (new Msg(19, arg0_18)), singleton((dispatch_12) => {
                            dispatch_12(new PbsListMsg(10, new AccessToFunctions_1(2)));
                        }))]));
                    }
                    case 5: {
                        return singleton((dispatch_13) => {
                            dispatch_13(new Msg(12));
                        });
                    }
                    default: {
                        return Cmd_none();
                    }
                }
            };
            const matchValue_4 = state.PbsTreeMenuState;
            if (matchValue_4 == null) {
                const patternInput_3 = update_2(pci, menuMsg, new PbsTreeMenuState_4(new ComponentState_5(1), new PbsMenuRestAddresses_1(PbsMenuApiAddress_fetchProjectAddress, PbsMenuApiAddress_fetchPbsChildrenAddress), void 0, void 0, void 0, void 0, void 0, empty(), void 0));
                const extraMsg_1 = extraMessage(patternInput_3[2]);
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, patternInput_3[0], state.PbsesListState), Cmd_batch(ofArray([Cmd_map((arg0_21) => (new Msg(17, arg0_21)), patternInput_3[1]), extraMsg_1]))];
            }
            else {
                const patternInput_2 = update_2(pci, menuMsg, matchValue_4);
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, patternInput_2[0], state.PbsesListState), (extraMsg = extraMessage(patternInput_2[2]), Cmd_batch(ofArray([Cmd_map((arg0_20) => (new Msg(17, arg0_20)), patternInput_2[1]), extraMsg])))];
            }
        }
        case 18: {
            return [state, empty()];
        }
        case 19: {
            const pbsMsg = msg.fields[0];
            const extraMessage_1 = (pbsListExtraCmd) => {
                let msg_29, tupledArg_4, msg_31, tupledArg_5;
                switch (pbsListExtraCmd.tag) {
                    case 1: {
                        const projectReference_1 = pbsListExtraCmd.fields[0];
                        const pbsReference_1 = pbsListExtraCmd.fields[1];
                        return Cmd_batch(ofArray([Cmd_map((arg0_22) => (new Msg(17, arg0_22)), (msg_29 = ((tupledArg_4 = [projectReference_1, pbsReference_1], new PbsTreeMenuMsg(5, tupledArg_4[0], tupledArg_4[1]))), singleton((dispatch_14) => {
                            dispatch_14(msg_29);
                        }))), (msg_31 = ((tupledArg_5 = [projectReference_1, pbsReference_1], new Msg(9, tupledArg_5[0], tupledArg_5[1]))), singleton((dispatch_15) => {
                            dispatch_15(msg_31);
                        }))]));
                    }
                    case 2: {
                        return singleton((dispatch_16) => {
                            dispatch_16(new Msg(12));
                        });
                    }
                    default: {
                        return Cmd_none();
                    }
                }
            };
            const matchValue_5 = state.PbsesListState;
            if (matchValue_5 == null) {
                const patternInput_5 = update_3(pci, pbsMsg, new PbsListComponentState(new PbsListRestAddresses(PbsListApiAddress_fetchPbsesAddress, PbsListApiAddress_fetchPbsPbsesAddress, PbsListApiAddress_fetchPbsTranslationsAddress, PbsListApiAddress_postUpdatePbsAddress, PbsListApiAddress_postCreatePbsTranslationAddress, PbsListApiAddress_postUpdatePbsTranslationAddress, PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress, PbsListApiAddress_postResetPbsCustomerDescriptionAddress), new AccessToFunctions_1(0), new ComponentState_5(1), empty(), void 0, void 0, void 0, void 0, void 0, empty(), void 0, empty(), empty(), empty(), empty(), empty(), empty(), empty()));
                const extraMsg_3 = extraMessage_1(patternInput_5[2]);
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, patternInput_5[0]), Cmd_batch(ofArray([Cmd_map((arg0_26) => (new Msg(19, arg0_26)), patternInput_5[1]), extraMsg_3]))];
            }
            else {
                const patternInput_4 = update_3(pci, pbsMsg, matchValue_5);
                const extraMsg_2 = extraMessage_1(patternInput_4[2]);
                return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, patternInput_4[0]), Cmd_batch(ofArray([Cmd_map((arg0_25) => (new Msg(19, arg0_25)), patternInput_4[1]), extraMsg_2]))];
            }
        }
        case 20: {
            return [new AppState(state.CurrentRoute, state.ComponentState, state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, void 0), empty()];
        }
        case 21: {
            const exn = msg.fields[0];
            const matchValue_6 = exn.message;
            if (matchValue_6.indexOf("no_account_error") >= 0) {
                return [state, singleton((dispatch_17) => {
                    dispatch_17(new Msg(0));
                })];
            }
            else {
                return [new AppState(state.CurrentRoute, new ComponentState_5(2, singleton(exn.message)), state.TargetConceptProjectInfoes, state.SelectedProjectInfo, state.WordExportComponentState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
            }
        }
        default: {
            return [state, loginRedirect(pci)];
        }
    }
}

