import { Union, Record } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { union_type, list_type, option_type, record_type, lambda_type, string_type, class_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { ComponentState$reflection } from "../../Types.js";
import { OnlineDocumentation$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { PbsMenu$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";

export class PbsMenuRestAddresses extends Record {
    constructor(FetchProjectAddress, FetchPbsChildrenAddress) {
        super();
        this.FetchProjectAddress = FetchProjectAddress;
        this.FetchPbsChildrenAddress = FetchPbsChildrenAddress;
    }
}

export function PbsMenuRestAddresses$reflection() {
    return record_type("PbsTreeMenuTypes.PbsMenuRestAddresses", [], PbsMenuRestAddresses, () => [["FetchProjectAddress", lambda_type(class_type("System.Guid"), string_type)], ["FetchPbsChildrenAddress", lambda_type(class_type("System.Guid"), string_type)]]);
}

export class PbsTreeMenuState extends Record {
    constructor(ComponentState, PbsMenuRestAddresses, OnlineDocumentation, SelectedProjectId, SelectedPbsId, SelectedService, SelectedServiceModule, ExpandedParentPbsesId, FilteredMenu) {
        super();
        this.ComponentState = ComponentState;
        this.PbsMenuRestAddresses = PbsMenuRestAddresses;
        this.OnlineDocumentation = OnlineDocumentation;
        this.SelectedProjectId = SelectedProjectId;
        this.SelectedPbsId = SelectedPbsId;
        this.SelectedService = SelectedService;
        this.SelectedServiceModule = SelectedServiceModule;
        this.ExpandedParentPbsesId = ExpandedParentPbsesId;
        this.FilteredMenu = FilteredMenu;
    }
}

export function PbsTreeMenuState$reflection() {
    return record_type("PbsTreeMenuTypes.PbsTreeMenuState", [], PbsTreeMenuState, () => [["ComponentState", ComponentState$reflection()], ["PbsMenuRestAddresses", PbsMenuRestAddresses$reflection()], ["OnlineDocumentation", option_type(OnlineDocumentation$reflection())], ["SelectedProjectId", option_type(XrmEntityReference$reflection())], ["SelectedPbsId", option_type(XrmEntityReference$reflection())], ["SelectedService", option_type(XrmEntityReference$reflection())], ["SelectedServiceModule", option_type(XrmEntityReference$reflection())], ["ExpandedParentPbsesId", list_type(class_type("System.Guid"))], ["FilteredMenu", option_type(PbsMenu$reflection())]]);
}

export class PbsTreeMenuExtraMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "SelectPbsExtraMsg", "ServiceFilterExtraMsg", "ServiceModuleFilterExraMsg", "ProjectResponseExtraMsg", "QuitFilterExtraMsg"];
    }
}

export function PbsTreeMenuExtraMsg$reflection() {
    return union_type("PbsTreeMenuTypes.PbsTreeMenuExtraMsg", [], PbsTreeMenuExtraMsg, () => [[], [["Item1", XrmEntityReference$reflection()], ["Item2", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [["Item", OnlineDocumentation$reflection()]], []]);
}

export class PbsTreeMenuMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginRedirectRequest", "LoginRedirectResponse", "ProjectRequest", "PbsChildrenRequest", "ProjectResponse", "PbsSelection", "ServiceFilter", "ServiceModuleFilter", "QuitFilter", "RestError"];
    }
}

export function PbsTreeMenuMsg$reflection() {
    return union_type("PbsTreeMenuTypes.PbsTreeMenuMsg", [], PbsTreeMenuMsg, () => [[], [["Item", string_type]], [["Item1", class_type("System.Guid")], ["Item2", option_type(class_type("System.Guid"))]], [["Item1", class_type("System.Guid")], ["Item2", option_type(class_type("System.Guid"))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentation$reflection()]], [["ErrorValue", string_type]]])]], [["Item1", XrmEntityReference$reflection()], ["Item2", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

