import * as style from "../../../../../../Aimtec.Components.React/Components/ErrorPage/style.scss";
import { createElement } from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { ofArray } from "../../../fable_modules/fable-library.3.7.11/List.js";


export const Render = createElement("div", createObj(ofArray([["className", "container"], (() => {
    const elems = [createElement("h1", {
        children: ["Route not found"],
    })];
    return ["children", Interop_reactApi.Children.toArray(Array.from(elems))];
})()])));

