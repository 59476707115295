import { map, defaultArg } from "../fable_modules/fable-library.3.7.11/Option.js";
import { createElement } from "react";
import * as react from "react";
import { Msg } from "./Types.js";
import { ofArray, singleton, append, map as map_1 } from "../fable_modules/fable-library.3.7.11/List.js";
import { createObj } from "../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.65.0/Interop.fs.js";

export function Render(renderInputProps) {
    const dispatch = renderInputProps.dispatch;
    return defaultArg(map((o) => {
        let elems_1, elems;
        const quitTranslationButtonOrFragment = (o.SelectedLanguageId != null) ? createElement("button", {
            className: "btn btn-warning",
            children: "Quit Translations",
            onClick: (_arg2) => {
                dispatch(new Msg(15));
            },
        }) : react.createElement(react.Fragment, {});
        const languageBtns = map_1((language) => createElement("button", {
            className: `${((langId) => defaultArg(map((slid) => {
                if (slid === langId) {
                    return "btn btn-secondary";
                }
                else {
                    return "btn btn-primary";
                }
            }, o.SelectedLanguageId), "btn btn-primary"))(language.Id)}`,
            onClick: (_arg1) => {
                dispatch(new Msg(14, language.Id));
            },
            children: language.Name,
        }), o.ProjectLanguages);
        return createElement("div", createObj(ofArray([["className", "row"], (elems_1 = [createElement("div", createObj(ofArray([["className", "btn-group"], (elems = append(languageBtns, singleton(quitTranslationButtonOrFragment)), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }, renderInputProps.selectedProjectInfoO), react.createElement(react.Fragment, {}));
}

