import { Record } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { record_type, class_type, lambda_type, unit_type, option_type, string_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { UserInfo$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import * as style from "../../../../../../Aimtec.Components.React/Components/AimtecHeader/style.scss";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { singleton, append, delay, toList } from "../../../fable_modules/fable-library.3.7.11/Seq.js";
import { join } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { ofArray } from "../../../fable_modules/fable-library.3.7.11/List.js";

export class HeaderPropps extends Record {
    constructor(AppNameAndVersion, ProjectReference, PbsReference, UserInfo, AuthenticationFunction, LogoClick, HeaderClick, ProjectClick) {
        super();
        this.AppNameAndVersion = AppNameAndVersion;
        this.ProjectReference = ProjectReference;
        this.PbsReference = PbsReference;
        this.UserInfo = UserInfo;
        this.AuthenticationFunction = AuthenticationFunction;
        this.LogoClick = LogoClick;
        this.HeaderClick = HeaderClick;
        this.ProjectClick = ProjectClick;
    }
}

export function HeaderPropps$reflection() {
    return record_type("AimtecHeaderComponentView.HeaderPropps", [], HeaderPropps, () => [["AppNameAndVersion", string_type], ["ProjectReference", option_type(XrmEntityReference$reflection())], ["PbsReference", option_type(XrmEntityReference$reflection())], ["UserInfo", option_type(UserInfo$reflection())], ["AuthenticationFunction", lambda_type(unit_type, unit_type)], ["LogoClick", lambda_type(unit_type, unit_type)], ["HeaderClick", lambda_type(unit_type, unit_type)], ["ProjectClick", lambda_type(class_type("System.Guid"), unit_type)]]);
}


export function Render(props) {
    let elems_7, elems_6;
    return createElement("nav", createObj(ofArray([["className", "navbar navbar-expand-lg navbar-dark bg-dark"], (elems_7 = [createElement("div", createObj(ofArray([["className", "container-fluid"], (elems_6 = toList(delay(() => append(singleton(createElement("img", {
        src: "img/cloud-logo.svg",
        className: "navbar-brand cursor-pointer",
        onClick: (_arg1) => {
            props.LogoClick();
        },
    })), delay(() => {
        let elems;
        return append(singleton(createElement("button", createObj(ofArray([["className", "navbar-toggler"], ["type", "button"], ["data-bs-toggle", "collapse"], ["data-bs-target", "#navbarSupportedContent"], ["aria-controls", join(" ", ["navbarSupportedContent"])], ["aria-expanded", false], ["aria-label", "Toggle navigation"], (elems = [createElement("span", {
            className: "navbar-toggler-icon",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1, elems_2, elems_5, elems_4, elems_3;
            let projectLabelOrFrag;
            const matchValue = props.ProjectReference;
            if (matchValue == null) {
                projectLabelOrFrag = react.createElement(react.Fragment, {});
            }
            else {
                const pr = matchValue;
                projectLabelOrFrag = createElement("li", createObj(ofArray([["className", "nav-item"], (elems_1 = [createElement("a", {
                    className: "nav-link active cursor-pointer",
                    children: ` | ${pr.Name}`,
                    onClick: (_arg2) => {
                        props.ProjectClick(pr.Id);
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            }
            let pbsLabelOrFrag;
            const matchValue_1 = props.PbsReference;
            if (matchValue_1 == null) {
                pbsLabelOrFrag = react.createElement(react.Fragment, {});
            }
            else {
                const pbs = matchValue_1;
                pbsLabelOrFrag = createElement("li", createObj(ofArray([["className", "nav-item"], (elems_2 = [createElement("a", {
                    className: "nav-link active",
                    children: ` | ${pbs.Name}`,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
            }
            let loginOrLogout;
            const matchValue_2 = props.UserInfo;
            if (matchValue_2 != null) {
                const ui = matchValue_2;
                loginOrLogout = createElement("a", {
                    className: "nav-lin authentication-label",
                    children: `${ui.FirstName} ${ui.LastName}`,
                    onClick: (_arg3) => {
                        props.AuthenticationFunction();
                    },
                });
            }
            else {
                loginOrLogout = react.createElement(react.Fragment, {});
            }
            return singleton(createElement("div", createObj(ofArray([["id", "navbarSupportedContent"], ["className", "collapse navbar-collapse"], (elems_5 = [createElement("ul", createObj(ofArray([["className", "navbar-nav me-auto mb-2 mb-lg-0"], (elems_4 = [createElement("li", createObj(ofArray([["className", "nav-item"], (elems_3 = [createElement("a", {
                className: "nav-link active cursor-pointer",
                children: props.AppNameAndVersion,
                onClick: (_arg4) => {
                    props.HeaderClick();
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), projectLabelOrFrag, pbsLabelOrFrag], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), loginOrLogout], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

