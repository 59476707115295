// TODO: import CKEditor from this file, not from public\index.html file
// something like:
//import * as dp from "./dependency";

export var appendTo = (editorId, editorContent, onChange) => {
    let editorInstance = window.CKEDITOR.appendTo(document.getElementById(editorId), {
        extraPlugins: 'autogrow,validate',
        autoGrow_onStartup: true,
        width: 'auto',
        toolbarGroups: [
            {name: 'aimtec', groups: ['validate']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker']},
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'links'},
            {name: 'insert'},
            {name: 'forms'},
            {name: 'tools'},
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            {name: 'others'},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi']},
            {name: 'colors'},
            {name: 'styles'}],
        removeButtons: 'Subscript`,Superscript,Image',
        format_tags: 'p;h1;h2;h3;pre',
        removeDialogTabs: 'image:advanced;link:advanced',
        shiftEnterMode: CKEDITOR.ENTER_P,
        enterMode: CKEDITOR.ENTER_BR
    }, editorContent);

    editorInstance.on('change', function () {
        onChange(editorInstance.getData());
    });

    return editorInstance;
};

export var destroy = (editorInstance) => {
    editorInstance.destroy()
};