import { Record, Union } from "../fable_modules/fable-library.3.7.11/Types.js";
import { string_type, record_type, list_type, union_type, option_type, class_type } from "../fable_modules/fable-library.3.7.11/Reflection.js";
import { parse } from "../fable_modules/fable-library.3.7.11/Guid.js";
import { TargetConceptProjectInfoes$reflection, ProjectInfo$reflection } from "../AIM/Aimtec.D365.Domain/Aimtec.D365.Project.Types.js";
import { XrmEntityReference$reflection } from "../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { ComponentState as ComponentState_1, ComponentState$reflection } from "../Aimtec.Components.React/Types.js";
import { WordExportComponentMsg$reflection, WordExportComponentState$reflection } from "../Aimtec.Components.React/Components/WordExport/Types.js";
import { PbsTreeMenuMsg$reflection, PbsTreeMenuState$reflection } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { PbsListMsg$reflection, PbsListComponentState$reflection } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.11/Choice.js";

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Root", "Projects", "Project", "Pbs", "Invalid"];
    }
}

export function Route$reflection() {
    return union_type("Types.Route", [], Route, () => [[], [["Item", option_type(class_type("System.Guid"))]], [["Item1", class_type("System.Guid")], ["Item2", option_type(class_type("System.Guid"))]], [["Item1", class_type("System.Guid")], ["Item2", option_type(class_type("System.Guid"))]], []]);
}

export function Route_ProjectsRouteFromParams_2297AD2E(projectId) {
    if (projectId == null) {
        return new Route(1, void 0);
    }
    else {
        return new Route(1, parse(projectId));
    }
}

export function Route_ProjectRouteFromParams(projectId, pbsId) {
    if (pbsId == null) {
        const tupledArg_1 = [parse(projectId), void 0];
        return new Route(2, tupledArg_1[0], tupledArg_1[1]);
    }
    else {
        const pbsId_1 = pbsId;
        const tupledArg = [parse(projectId), parse(pbsId_1)];
        return new Route(2, tupledArg[0], tupledArg[1]);
    }
}

export function Route_PbsRouteFromParams(parentPbsId, selectedPbsId) {
    if (selectedPbsId == null) {
        const tupledArg_1 = [parse(parentPbsId), void 0];
        return new Route(3, tupledArg_1[0], tupledArg_1[1]);
    }
    else {
        const pbsId = selectedPbsId;
        const tupledArg = [parse(parentPbsId), parse(pbsId)];
        return new Route(3, tupledArg[0], tupledArg[1]);
    }
}

export class SelectedProjectInfo extends Record {
    constructor(SelectedProject, ProjectLanguages, SelectedLanguageId) {
        super();
        this.SelectedProject = SelectedProject;
        this.ProjectLanguages = ProjectLanguages;
        this.SelectedLanguageId = SelectedLanguageId;
    }
}

export function SelectedProjectInfo$reflection() {
    return record_type("Types.SelectedProjectInfo", [], SelectedProjectInfo, () => [["SelectedProject", ProjectInfo$reflection()], ["ProjectLanguages", list_type(XrmEntityReference$reflection())], ["SelectedLanguageId", option_type(class_type("System.Guid"))]]);
}

export class AppState extends Record {
    constructor(CurrentRoute, ComponentState, TargetConceptProjectInfoes, SelectedProjectInfo, WordExportComponentState, PbsTreeMenuState, PbsesListState) {
        super();
        this.CurrentRoute = CurrentRoute;
        this.ComponentState = ComponentState;
        this.TargetConceptProjectInfoes = TargetConceptProjectInfoes;
        this.SelectedProjectInfo = SelectedProjectInfo;
        this.WordExportComponentState = WordExportComponentState;
        this.PbsTreeMenuState = PbsTreeMenuState;
        this.PbsesListState = PbsesListState;
    }
}

export function AppState$reflection() {
    return record_type("Types.AppState", [], AppState, () => [["CurrentRoute", Route$reflection()], ["ComponentState", ComponentState$reflection()], ["TargetConceptProjectInfoes", option_type(TargetConceptProjectInfoes$reflection())], ["SelectedProjectInfo", option_type(SelectedProjectInfo$reflection())], ["WordExportComponentState", option_type(WordExportComponentState$reflection())], ["PbsTreeMenuState", option_type(PbsTreeMenuState$reflection())], ["PbsesListState", option_type(PbsListComponentState$reflection())]]);
}

export function AppState_get_Empty() {
    return new AppState(new Route(0), new ComponentState_1(1), void 0, void 0, void 0, void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginRedirectReq", "LoginRedirectRes", "NavigateHome", "NavigateProjectHome", "NavigateToProject", "NavigateToPbs", "GoToPbs", "RequestTargetConceptProjectInfoes", "ReceiveTargetConceptProjectInfoes", "SelectPbs", "SelectService", "SelectServiceModule", "QuitServiceFilter", "SetProjectInfoLanguages", "SelectLanguage", "DeselectLanguage", "WordExportMessages", "MenuMessages", "QuitMenu", "PbsMessages", "QuitPbs", "UIError"];
    }
}

export function Msg$reflection() {
    return union_type("Types.Msg", [], Msg, () => [[], [["Item", string_type]], [], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item", option_type(class_type("System.Guid"))]], [["Item1", option_type(class_type("System.Guid"))], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [TargetConceptProjectInfoes$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", TargetConceptProjectInfoes$reflection()]], [["ErrorValue", string_type]]])]], [["Item1", XrmEntityReference$reflection()], ["Item2", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [], [["Item1", ProjectInfo$reflection()], ["Item2", list_type(XrmEntityReference$reflection())]], [["Item", class_type("System.Guid")]], [], [["Item", WordExportComponentMsg$reflection()]], [["Item", PbsTreeMenuMsg$reflection()]], [], [["Item", PbsListMsg$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

