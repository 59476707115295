import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { Render as Render_1 } from "../../ErrorPage/ErrorPage.js";
import { singleton } from "../../../../fable_modules/fable-library.3.7.11/List.js";


export function Render(renderInputProps) {
    const onResetError = renderInputProps.onResetError;
    return Render_1(singleton(renderInputProps.errorMessage), onResetError);
}

