import { Union, Record } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { enum_type, int32_type, union_type, option_type, record_type, class_type, lambda_type, string_type, unit_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { ComponentState$reflection } from "../../Types.js";
import { ExportInfo$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.WordExport.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";

export class WordExportRestAddresses extends Record {
    constructor(ExportToWordAddress, DownloadWordAddress, ProjectExportInfo) {
        super();
        this.ExportToWordAddress = ExportToWordAddress;
        this.DownloadWordAddress = DownloadWordAddress;
        this.ProjectExportInfo = ProjectExportInfo;
    }
}

export function WordExportRestAddresses$reflection() {
    return record_type("WordExportComponentTypes.WordExportRestAddresses", [], WordExportRestAddresses, () => [["ExportToWordAddress", lambda_type(unit_type, string_type)], ["DownloadWordAddress", lambda_type(class_type("System.Guid"), string_type)], ["ProjectExportInfo", lambda_type(class_type("System.Guid"), string_type)]]);
}

export class WordExportComponentState extends Record {
    constructor(ComponentState, WordExportRestAddresses, SelectedProjectId, DefaultLanguageId, ExportLanguageId, SelectedPbsId, ExportInfo) {
        super();
        this.ComponentState = ComponentState;
        this.WordExportRestAddresses = WordExportRestAddresses;
        this.SelectedProjectId = SelectedProjectId;
        this.DefaultLanguageId = DefaultLanguageId;
        this.ExportLanguageId = ExportLanguageId;
        this.SelectedPbsId = SelectedPbsId;
        this.ExportInfo = ExportInfo;
    }
}

export function WordExportComponentState$reflection() {
    return record_type("WordExportComponentTypes.WordExportComponentState", [], WordExportComponentState, () => [["ComponentState", ComponentState$reflection()], ["WordExportRestAddresses", WordExportRestAddresses$reflection()], ["SelectedProjectId", class_type("System.Guid")], ["DefaultLanguageId", class_type("System.Guid")], ["ExportLanguageId", option_type(class_type("System.Guid"))], ["SelectedPbsId", option_type(class_type("System.Guid"))], ["ExportInfo", option_type(ExportInfo$reflection())]]);
}

export class WordExportComponentMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectExportInfoRequest", "ProjectExportInfoResponse", "SetExportLanguageId", "RemoveExportLanguage", "ExportRequest", "ExportResponse", "ResetExportResult", "RESTError"];
    }
}

export function WordExportComponentMsg$reflection() {
    return union_type("WordExportComponentTypes.WordExportComponentMsg", [], WordExportComponentMsg, () => [[["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]])]], [["Item", class_type("System.Guid")]], [], [["Item1", class_type("System.Guid")], ["Item2", option_type(class_type("System.Guid"))], ["Item3", enum_type("Aimtec.WordExport.ExportModeSet", int32_type, [["Standard", 0], ["RoleModule", 1], ["KeyuserConsultantEN", 2], ["KeyuserConsultantCZ", 3]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ExportInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ExportInfo$reflection()]], [["ErrorValue", string_type]]])]], [], [["Item", class_type("System.Exception")]]]);
}

