import { Record } from "../../fable_modules/fable-library.3.7.11/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.11/Reflection.js";

export class XrmEntityReference extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function XrmEntityReference$reflection() {
    return record_type("Aimtec.D365.Entity.XrmEntityReference", [], XrmEntityReference, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

