import { Record } from "../../fable_modules/fable-library.3.7.11/Types.js";
import { ProjectInfo$reflection } from "./Aimtec.D365.Project.Types.js";
import { PbsMenuItemModule_fromPbs, PbsOperations_createMenu, PbsMenu$reflection, Pbs$reflection } from "./Aimtec.D365.Pbs.Types.js";
import { string_type, class_type, record_type, list_type } from "../../fable_modules/fable-library.3.7.11/Reflection.js";
import { XrmEntityReference$reflection } from "./Aimtec.D365.Entity.Types.js";
import { ofSeq } from "../../fable_modules/fable-library.3.7.11/List.js";
import { filter, map } from "../../fable_modules/fable-library.3.7.11/Seq.js";
import { distinct } from "../../fable_modules/fable-library.3.7.11/Seq2.js";
import { value } from "../../fable_modules/fable-library.3.7.11/Option.js";
import { safeHash, equals } from "../../fable_modules/fable-library.3.7.11/Util.js";
import { ProjectAccess$reflection } from "./Aimtec.D365.Pas.Types.js";

export class OnlineDocumentation extends Record {
    constructor(Project, Pbses, Menu, Languages, ServiceModules) {
        super();
        this.Project = Project;
        this.Pbses = Pbses;
        this.Menu = Menu;
        this.Languages = Languages;
        this.ServiceModules = ServiceModules;
    }
}

export function OnlineDocumentation$reflection() {
    return record_type("Aimtec.D365.Odc.OnlineDocumentation", [], OnlineDocumentation, () => [["Project", ProjectInfo$reflection()], ["Pbses", list_type(Pbs$reflection())], ["Menu", PbsMenu$reflection()], ["Languages", list_type(XrmEntityReference$reflection())], ["ServiceModules", list_type(XrmEntityReference$reflection())]]);
}

export function OnlineDocumentation_fromProjectAndPbses(project, pbsesSeq, languages) {
    const menu = PbsOperations_createMenu(ofSeq(map(PbsMenuItemModule_fromPbs, pbsesSeq)));
    const productModules = ofSeq(distinct(map((pbs_2) => value(pbs_2.ProjectServiceModule), filter((pbs_1) => (pbs_1.ProjectServiceModule != null), pbsesSeq)), {
        Equals: equals,
        GetHashCode: safeHash,
    }));
    return new OnlineDocumentation(project, ofSeq(pbsesSeq), menu, languages, productModules);
}

export class UserInfo extends Record {
    constructor(Id, FirstName, LastName, UserName, EmailAddress, ParentCustomerReference) {
        super();
        this.Id = Id;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.UserName = UserName;
        this.EmailAddress = EmailAddress;
        this.ParentCustomerReference = ParentCustomerReference;
    }
}

export function UserInfo$reflection() {
    return record_type("Aimtec.D365.Odc.UserInfo", [], UserInfo, () => [["Id", class_type("System.Guid")], ["FirstName", string_type], ["LastName", string_type], ["UserName", string_type], ["EmailAddress", string_type], ["ParentCustomerReference", XrmEntityReference$reflection()]]);
}

export class OnlineDocumentationSession extends Record {
    constructor(UserInfo, ProjectAccesses) {
        super();
        this.UserInfo = UserInfo;
        this.ProjectAccesses = ProjectAccesses;
    }
}

export function OnlineDocumentationSession$reflection() {
    return record_type("Aimtec.D365.Odc.OnlineDocumentationSession", [], OnlineDocumentationSession, () => [["UserInfo", UserInfo$reflection()], ["ProjectAccesses", list_type(ProjectAccess$reflection())]]);
}

