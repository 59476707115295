import { fetch$, Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.2.0/Fetch.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { Convert_fromJson, Convert_serialize } from "../../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../../../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { Pbs$reflection, PbsToEdit$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { Cmd_none, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { SimpleJson_tryParse } from "../../../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { class_type, union_type, string_type, tuple_type, list_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.11/Choice.js";
import { PbsListMsg } from "./Types.js";

export const getRequestProps = singleton(new Types_RequestProperties(4, "include"));

export function postRequestProps(jsonObject) {
    return ofArray([new Types_RequestProperties(1, {
        ["Content-Type"]: "application/json",
    }), new Types_RequestProperties(4, "include"), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, Convert_serialize(jsonObject, createTypeInfo(PbsToEdit$reflection())))]);
}

export function fetchPbses(address, projectId) {
    return Cmd_OfPromise_either((projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1);
        return fetch$(address_1, getRequestProps).then((_arg1) => (_arg1.text().then((_arg2) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg2), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection()))]], [["ErrorValue", string_type]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })), projectId, (arg0) => (new PbsListMsg(4, arg0)), (arg0_1) => (new PbsListMsg(36, arg0_1)));
}

export function fetchTranslations(address, projectId, languageId) {
    return Cmd_none();
}

export function postCreatePbsTranslation(address, createTranslationParams) {
    return Cmd_none();
}

export function postUpdatePbs(address, saveAndClose, pbsToUpdate) {
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then((_arg1) => (_arg1.text().then((_arg2) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg2), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })), pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(28, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsTranslation(address, saveAndClose, pbsToUpdate) {
    return Cmd_none();
}

export function postUpdatePbsCustomerDescription(address, pbsToUpdate) {
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then((_arg1) => (_arg1.text().then((_arg2) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg2), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })), pbsToUpdate, (arg0) => (new PbsListMsg(18, arg0)), (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

export function postResetCustomerDescription(address, pbsToUpdate) {
    return Cmd_OfPromise_either((pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then((_arg1) => (_arg1.text().then((_arg2) => {
            let matchValue;
            return Promise.resolve((matchValue = SimpleJson_tryParse(_arg2), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        })));
    })), pbsToUpdate, (arg0) => (new PbsListMsg(18, arg0)), (exn) => (new PbsListMsg(29, pbsToUpdate.Id, exn.message)));
}

