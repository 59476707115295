import { destroy as destroy_1, appendTo as appendTo_1 } from "../../../../../../Aimtec.Components.React/Components/CKEDITOR/ckeditor-interop.js";
import { Record } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { class_type, obj_type, record_type, lambda_type, unit_type, string_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { HTMLAttr } from "../../../fable_modules/Fable.React.8.0.1/Fable.React.Props.fs.js";
import { Component } from "react";
import * as react from "react";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.11/MapUtil.js";

export const appendTo = appendTo_1;

export const destroy = destroy_1;

export class EditorProps extends Record {
    constructor(ckeditorId, ckeditorName, ckedtiorText, ckeditorChangeFunction, relatedItemId) {
        super();
        this.ckeditorId = ckeditorId;
        this.ckeditorName = ckeditorName;
        this.ckedtiorText = ckedtiorText;
        this.ckeditorChangeFunction = ckeditorChangeFunction;
        this.relatedItemId = relatedItemId;
    }
}

export function EditorProps$reflection() {
    return record_type("CKEditor.EditorProps", [], EditorProps, () => [["ckeditorId", string_type], ["ckeditorName", string_type], ["ckedtiorText", string_type], ["ckeditorChangeFunction", lambda_type(string_type, unit_type)], ["relatedItemId", string_type]]);
}

export class CKEditorComponent extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.editorInstance = "";
    }
    componentDidMount() {
        const this$ = this;
        const props = this$.props;
        const editorId = props.ckeditorId + props.ckeditorName;
        this$.editorInstance = appendTo(editorId, props.ckedtiorText, props.ckeditorChangeFunction);
    }
    render() {
        const this$ = this;
        const props = [new HTMLAttr(99, this$.props.ckeditorId + this$.props.ckeditorName), new HTMLAttr(123, this$.props.ckeditorName)];
        return react.createElement("div", keyValueList(props, 1));
    }
    componentWillUnmount() {
        const this$ = this;
        destroy(this$.editorInstance);
    }
}

export function CKEditorComponent$reflection() {
    return class_type("CKEditor.CKEditorComponent", void 0, CKEditorComponent, class_type("Fable.React.Component`2", [EditorProps$reflection(), obj_type], Component));
}

export function CKEditorComponent_$ctor_695B9DED(props) {
    return new CKEditorComponent(props);
}

