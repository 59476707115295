import { Record } from "../../fable_modules/fable-library.3.7.11/Types.js";
import { tuple_type, option_type, list_type, record_type, int32_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.11/Reflection.js";
import { XrmEntityReference, XrmEntityReference$reflection } from "./Aimtec.D365.Entity.Types.js";
import { PbsMenu$reflection, PbsTranslation$reflection, Pbs$reflection } from "./Aimtec.D365.Pbs.Types.js";

export class ProjectInfo extends Record {
    constructor(Id, Name, Author, Account, PbsesCount, DefaultLanguage) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Author = Author;
        this.Account = Account;
        this.PbsesCount = (PbsesCount | 0);
        this.DefaultLanguage = DefaultLanguage;
    }
}

export function ProjectInfo$reflection() {
    return record_type("Aimtec.D365.Project.ProjectInfo", [], ProjectInfo, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Author", string_type], ["Account", string_type], ["PbsesCount", int32_type], ["DefaultLanguage", XrmEntityReference$reflection()]]);
}

export class TargetConceptProjectInfoes extends Record {
    constructor(ActiveProjects, InactiveProjects) {
        super();
        this.ActiveProjects = ActiveProjects;
        this.InactiveProjects = InactiveProjects;
    }
}

export function TargetConceptProjectInfoes$reflection() {
    return record_type("Aimtec.D365.Project.TargetConceptProjectInfoes", [], TargetConceptProjectInfoes, () => [["ActiveProjects", list_type(ProjectInfo$reflection())], ["InactiveProjects", list_type(ProjectInfo$reflection())]]);
}

export class Project extends Record {
    constructor(Id, ProjectInfo, Pbses, Menu, Languages) {
        super();
        this.Id = Id;
        this.ProjectInfo = ProjectInfo;
        this.Pbses = Pbses;
        this.Menu = Menu;
        this.Languages = Languages;
    }
}

export function Project$reflection() {
    return record_type("Aimtec.D365.Project.Project", [], Project, () => [["Id", class_type("System.Guid")], ["ProjectInfo", ProjectInfo$reflection()], ["Pbses", list_type(tuple_type(Pbs$reflection(), option_type(PbsTranslation$reflection())))], ["Menu", PbsMenu$reflection()], ["Languages", list_type(XrmEntityReference$reflection())]]);
}

export const Language_English = new XrmEntityReference("177f04f4-017c-e211-83e6-005056975d80", "English");

