import { Record } from "../../fable_modules/fable-library.3.7.11/Types.js";
import { lambda_type, list_type, tuple_type, enum_type, record_type, int32_type, option_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.11/Reflection.js";

export class ExportEntity extends Record {
    constructor(Id, Name, Role, Module, AssignedTo, AssignedToCustomer, ProjectAccount, Description, Level) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Role = Role;
        this.Module = Module;
        this.AssignedTo = AssignedTo;
        this.AssignedToCustomer = AssignedToCustomer;
        this.ProjectAccount = ProjectAccount;
        this.Description = Description;
        this.Level = (Level | 0);
    }
}

export function ExportEntity$reflection() {
    return record_type("Aimtec.WordExport.ExportEntity", [], ExportEntity, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Role", option_type(string_type)], ["Module", option_type(string_type)], ["AssignedTo", option_type(string_type)], ["AssignedToCustomer", option_type(string_type)], ["ProjectAccount", string_type], ["Description", string_type], ["Level", int32_type]]);
}

export class ExportMode extends Record {
    constructor(Name, Mode) {
        super();
        this.Name = Name;
        this.Mode = (Mode | 0);
    }
}

export function ExportMode$reflection() {
    return record_type("Aimtec.WordExport.ExportMode", [], ExportMode, () => [["Name", string_type], ["Mode", enum_type("Aimtec.WordExport.ExportModeSet", int32_type, [["Standard", 0], ["RoleModule", 1], ["KeyuserConsultantEN", 2], ["KeyuserConsultantCZ", 3]])]]);
}

export class ExportExtension extends Record {
    constructor(Name, Mode, GetValue) {
        super();
        this.Name = Name;
        this.Mode = (Mode | 0);
        this.GetValue = GetValue;
    }
}

export function ExportExtension$reflection() {
    return record_type("Aimtec.WordExport.ExportExtension", [], ExportExtension, () => [["Name", string_type], ["Mode", enum_type("Aimtec.WordExport.ExportModeSet", int32_type, [["Standard", 0], ["RoleModule", 1], ["KeyuserConsultantEN", 2], ["KeyuserConsultantCZ", 3]])], ["GetValue", lambda_type(ExportEntity$reflection(), list_type(option_type(tuple_type(string_type, string_type))))]]);
}

export class Field extends Record {
    constructor(Key, GetValue) {
        super();
        this.Key = Key;
        this.GetValue = GetValue;
    }
}

export function Field$reflection() {
    return record_type("Aimtec.WordExport.Field", [], Field, () => [["Key", string_type], ["GetValue", lambda_type(ExportEntity$reflection(), option_type(string_type))]]);
}

export class ExportInfo extends Record {
    constructor(Html, Error$, Exceptions, ExportStamp) {
        super();
        this.Html = (Html | 0);
        this.Error = (Error$ | 0);
        this.Exceptions = Exceptions;
        this.ExportStamp = ExportStamp;
    }
}

export function ExportInfo$reflection() {
    return record_type("Aimtec.WordExport.ExportInfo", [], ExportInfo, () => [["Html", int32_type], ["Error", int32_type], ["Exceptions", list_type(tuple_type(string_type, string_type, string_type))], ["ExportStamp", string_type]]);
}

export class ExportPostParams extends Record {
    constructor(ProjectId, LanguageId, PbsToExportId, ExportModeSet) {
        super();
        this.ProjectId = ProjectId;
        this.LanguageId = LanguageId;
        this.PbsToExportId = PbsToExportId;
        this.ExportModeSet = (ExportModeSet | 0);
    }
}

export function ExportPostParams$reflection() {
    return record_type("Aimtec.WordExport.ExportPostParams", [], ExportPostParams, () => [["ProjectId", class_type("System.Guid")], ["LanguageId", class_type("System.Guid")], ["PbsToExportId", class_type("System.Guid")], ["ExportModeSet", enum_type("Aimtec.WordExport.ExportModeSet", int32_type, [["Standard", 0], ["RoleModule", 1], ["KeyuserConsultantEN", 2], ["KeyuserConsultantCZ", 3]])]]);
}

