import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { fetchProjectExportInfo, postWordExport } from "./RestMsal.js";
import { ComponentState as ComponentState_3 } from "../../Types.js";
import { WordExportComponentMsg, WordExportComponentState } from "./Types.js";
import { singleton, empty } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { map, defaultArg } from "../../../fable_modules/fable-library.3.7.11/Option.js";
import { ExportPostParams } from "../../../AIM/Aimtec.D365.Domain/Aimtec.WordExport.js";

export function update(uao, msg, state) {
    let msg_1, msg_3;
    let postWordExportFunction;
    if (uao == null) {
        postWordExportFunction = ((_arg2) => ((_arg1) => Cmd_none()));
    }
    else {
        const pci = uao;
        postWordExportFunction = ((address) => ((wordExportPostParams) => postWordExport(pci, address, wordExportPostParams)));
    }
    let fetchProjectExportInfoFunction;
    if (uao == null) {
        fetchProjectExportInfoFunction = ((_arg4) => ((_arg3) => Cmd_none()));
    }
    else {
        const pci_1 = uao;
        fetchProjectExportInfoFunction = ((address_1) => ((projectId) => fetchProjectExportInfo(pci_1, address_1, projectId)));
    }
    switch (msg.tag) {
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 0) {
                return [new WordExportComponentState(new ComponentState_3(0), state.WordExportRestAddresses, state.SelectedProjectId, result.fields[0], state.ExportLanguageId, state.SelectedPbsId, state.ExportInfo), empty()];
            }
            else {
                return [state, (msg_1 = (new WordExportComponentMsg(7, new Error(result.fields[0]))), singleton((dispatch) => {
                    dispatch(msg_1);
                }))];
            }
        }
        case 2: {
            return [new WordExportComponentState(state.ComponentState, state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, msg.fields[0], state.SelectedPbsId, state.ExportInfo), Cmd_none()];
        }
        case 3: {
            return [new WordExportComponentState(state.ComponentState, state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, void 0, state.SelectedPbsId, state.ExportInfo), Cmd_none()];
        }
        case 4: {
            const pbsToExport = msg.fields[1];
            return [new WordExportComponentState(new ComponentState_3(1), state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, state.ExportLanguageId, state.SelectedPbsId, state.ExportInfo), postWordExportFunction(state.WordExportRestAddresses.ExportToWordAddress)(new ExportPostParams(msg.fields[0], defaultArg(map((i) => i, state.ExportLanguageId), state.DefaultLanguageId), (pbsToExport == null) ? "00000000-0000-0000-0000-000000000000" : pbsToExport, msg.fields[2]))];
        }
        case 5: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 0) {
                return [new WordExportComponentState(new ComponentState_3(0), state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, state.ExportLanguageId, state.SelectedPbsId, result_1.fields[0]), empty()];
            }
            else {
                return [state, (msg_3 = (new WordExportComponentMsg(7, new Error(result_1.fields[0]))), singleton((dispatch_1) => {
                    dispatch_1(msg_3);
                }))];
            }
        }
        case 6: {
            return [new WordExportComponentState(state.ComponentState, state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, state.ExportLanguageId, state.SelectedPbsId, void 0), Cmd_none()];
        }
        case 7: {
            return [new WordExportComponentState(new ComponentState_3(2, singleton(msg.fields[0].message)), state.WordExportRestAddresses, state.SelectedProjectId, state.DefaultLanguageId, state.ExportLanguageId, state.SelectedPbsId, state.ExportInfo), empty()];
        }
        default: {
            const projectId_1 = msg.fields[0];
            return [new WordExportComponentState(new ComponentState_3(0), state.WordExportRestAddresses, projectId_1, state.DefaultLanguageId, state.ExportLanguageId, state.SelectedPbsId, state.ExportInfo), fetchProjectExportInfoFunction(state.WordExportRestAddresses.ProjectExportInfo)(projectId_1)];
        }
    }
}

