import { MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505, MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0, MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505, MsalBuilders_SilentRequestBuilder__Account_31E51057, MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D, Msal_createPublicClientApplication, MsalBuilders_browserAuthOptions, MsalBuilders_BrowserAuthOptionsBuilder__Yield_1505, MsalBuilders_BrowserAuthOptionsBuilder__ClientId_33743E55, MsalBuilders_BrowserAuthOptionsBuilder__Authority_33743E55, MsalBuilders_configuration, MsalBuilders_ConfigurationBuilder__Yield_1505, MsalBuilders_ConfigurationBuilder__Auth_27E9ADC4 } from "../Fable/Fable.Msal/Fable.Msal.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.0.0-beta-3/program.fs.js";
import { update, init } from "./State.js";
import { createElement } from "react";
import { Render } from "./ReactApp.js";
import { ProgramModule_run } from "../fable_modules/Fable.Elmish.4.0.0-beta-3/program.fs.js";
import { Program_withReactSynchronous } from "../fable_modules/Fable.Elmish.React.4.0.0-beta-2/react.fs.js";
import { ProgramModule_toNavigable } from "../fable_modules/Fable.Elmish.Browser.4.0.0-beta-6/navigation.fs.js";
import { parseHash } from "../fable_modules/Fable.Elmish.Browser.4.0.0-beta-6/parser.fs.js";
import { urlUpdate, pageParser } from "./Router.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.11/List.js";
import { some } from "../fable_modules/fable-library.3.7.11/Option.js";

export const pciConfig = MsalBuilders_ConfigurationBuilder__Auth_27E9ADC4(MsalBuilders_configuration, MsalBuilders_ConfigurationBuilder__Yield_1505(MsalBuilders_configuration), MsalBuilders_BrowserAuthOptionsBuilder__Authority_33743E55(MsalBuilders_browserAuthOptions, MsalBuilders_BrowserAuthOptionsBuilder__ClientId_33743E55(MsalBuilders_browserAuthOptions, MsalBuilders_BrowserAuthOptionsBuilder__Yield_1505(MsalBuilders_browserAuthOptions), "77453588-0249-41c0-bdf7-6f90c64ff79e"), "https://login.microsoftonline.com/b3811028-ce6e-4b01-bcb0-db419328ffc5"));

export const pci = Msal_createPublicClientApplication(pciConfig);

export function authenticatedProgram(pci_1) {
    return ProgramModule_mkProgram(init, (msg, state) => update(pci_1, msg, state), (state_1, dispatch) => createElement(Render, {
        state: state_1,
        dispatch: dispatch,
    }));
}

export function createProgram(program) {
    ProgramModule_run(Program_withReactSynchronous("aimtec-tgc-app", ProgramModule_toNavigable((location) => parseHash(pageParser, location), urlUpdate, program)));
}

(function () {
    let pr_2;
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.handleRedirectPromise().then((_arg1) => {
        const authResult = _arg1;
        const silentRequest = MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Account_31E51057(MsalBuilders_silentRequest, MsalBuilders_SilentRequestBuilder__Yield_1505(MsalBuilders_silentRequest), pci.getAllAccounts()[0]), ofArray(["openid", "profile"]));
        if (authResult == null) {
            return pci.acquireTokenSilent(silentRequest).then((_arg3) => {
                createProgram(authenticatedProgram(pci));
                return Promise.resolve();
            });
        }
        else {
            const authResult_1 = authResult;
            return pci.acquireTokenSilent(silentRequest).then((_arg2) => {
                createProgram(authenticatedProgram(pci));
                return Promise.resolve();
            });
        }
    }))));
    pr_2 = (pr_1.then(void 0, ((e) => {
        if ((((e.message.indexOf("no_account_error") >= 0) ? true : (e.message.indexOf("monitor_window_timeout") >= 0)) ? true : (e.message.indexOf("interaction_required") >= 0)) ? true : (e.message.indexOf("no_tokens_found") >= 0)) {
            const redirectRequest = MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0(MsalBuilders_redirectRequest, MsalBuilders_RedirectRequestBuilder__Yield_1505(MsalBuilders_redirectRequest), ofArray(["openid", "profile"]));
            const pr = pci.loginRedirect(redirectRequest);
            pr.then();
        }
        else {
            window.alert(some(e.message));
        }
    })));
    pr_2.then();
})();

