import { PbsTreeMenuMsg } from "./Types.js";
import { sortBy, ofArray, map } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";

export function Render(productModules, selectedServiceModuleRef, dispatch) {
    const onModuleSelect = (id) => {
        dispatch(new PbsTreeMenuMsg(7, id));
    };
    const xs_2 = map((productModule) => {
        let elems;
        const patternInput = (selectedServiceModuleRef != null) ? ((selectedServiceModuleRef.Id === productModule.Id) ? ["btn btn-margin-1 btn-sm btn-secondary", (_arg3) => {
            dispatch(new PbsTreeMenuMsg(8));
        }] : ["btn btn-margin-1 btn-sm btn-outline-secondary", (_arg4) => {
            onModuleSelect(productModule);
        }]) : ["btn btn-margin-1 btn-sm btn-outline-secondary", (_arg2) => {
            onModuleSelect(productModule);
        }];
        return createElement("button", createObj(ofArray([["className", patternInput[0]], ["onClick", patternInput[1]], (elems = [createElement("a", {
            children: productModule.Name,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, sortBy((pm) => pm.Name, productModules, {
        Compare: comparePrimitives,
    }));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

