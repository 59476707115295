import { Render as Render_1 } from "../ErrorPage/ErrorPage.js";
import * as style from "../../../../../../Aimtec.Components.React/Components/PbsTreeMenu/style.scss";
import { Render as Render_2 } from "./ModuleFilter.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../../fable_modules/Feliz.1.65.0/React.fs.js";
import { ofArray, ofSeq, map, length, empty, singleton, append, contains } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { createObj, stringHash } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { createElement } from "react";
import * as react from "react";
import { List_distinct } from "../../../fable_modules/fable-library.3.7.11/Seq2.js";
import { List_remove } from "../../../AIM/Aimtec.D365.Domain/CollectionExtensions.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { PbsTreeMenuMsg } from "./Types.js";
import { toString } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { join, printf, toText } from "../../../fable_modules/fable-library.3.7.11/String.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { singleton as singleton_1, append as append_1, delay, toList } from "../../../fable_modules/fable-library.3.7.11/Seq.js";
import { Style, Render as Render_3 } from "../Preloader/Preloader.js";

export function Render(renderInputProps) {
    let elems_3;
    const onExportChildren = renderInputProps.onExportChildren;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const matchValue = state.ComponentState;
    switch (matchValue.tag) {
        case 2: {
            return Render_1(matchValue.fields[0], void 0);
        }
        case 0: {
            const matchValue_1 = state.OnlineDocumentation;
            if (matchValue_1 != null) {
                const odc = matchValue_1;
                const projectReference = odc.Project;
                const pbsReference = state.SelectedPbsId;
                const moduleFilterCmp = Render_2(odc.ServiceModules, state.SelectedServiceModule, dispatch);
                const patternInput = useFeliz_React__React_useState_Static_1505(state.ExpandedParentPbsesId);
                const setExpandedPbsesIds = patternInput[1];
                const expandedPbsesIds = patternInput[0];
                const isExpanded = (id_2) => contains(id_2, expandedPbsesIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                });
                const processesMenuItem = (pbsReference_2, hasChildren, openRequestCount, totalRequestCount) => {
                    let arg30, arg20;
                    const xs_3 = [createElement("a", {
                        className: "expander-collapser",
                        onClick: (_arg1) => {
                            if (isExpanded(pbsReference_2.Id)) {
                                setExpandedPbsesIds(List_distinct(List_remove(pbsReference_2.Id, expandedPbsesIds), {
                                    Equals: (x_1, y_1) => (x_1 === y_1),
                                    GetHashCode: stringHash,
                                }));
                            }
                            else {
                                setExpandedPbsesIds(List_distinct(append(expandedPbsesIds, singleton(pbsReference_2.Id)), {
                                    Equals: (x, y) => (x === y),
                                    GetHashCode: stringHash,
                                }));
                            }
                        },
                        children: hasChildren ? (isExpanded(pbsReference_2.Id) ? "-" : "+") : "",
                    }), createElement("a", {
                        style: createObj(((pbsReference == null) ? false : (pbsReference_2.Id === pbsReference.Id)) ? singleton(["textDecoration", "underline"]) : empty()),
                        className: "",
                        onClick: (_arg2) => {
                            let tupledArg;
                            dispatch((tupledArg = [new XrmEntityReference(projectReference.Id, projectReference.Name), pbsReference_2], new PbsTreeMenuMsg(5, tupledArg[0], tupledArg[1])));
                        },
                        children: (arg30 = toString(totalRequestCount), (arg20 = toString(openRequestCount), toText(printf("%s (%s/%s)"))(pbsReference_2.Name)(arg20)(arg30))),
                    })];
                    return react.createElement(react.Fragment, {}, ...xs_3);
                };
                const createMenu = (menu_1) => {
                    let elems;
                    if (length(menu_1.Children) === 0) {
                        const menu_3 = menu_1;
                        return processesMenuItem(new XrmEntityReference(menu_3.Parent.Id, menu_3.Parent.Name), false, menu_3.Parent.OpenRequestsCount, menu_3.Parent.TotalRequestsCount);
                    }
                    else {
                        const menu_4 = menu_1;
                        const xs_5 = [processesMenuItem(new XrmEntityReference(menu_4.Parent.Id, menu_4.Parent.Name), true, menu_4.Parent.OpenRequestsCount, menu_4.Parent.TotalRequestsCount), createElement("ul", createObj(ofArray([["style", createObj(isExpanded(menu_4.Parent.Id) ? singleton(["display", "contents"]) : singleton(["display", "none"]))], (elems = map(createMenu, ofSeq(menu_4.Children)), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))];
                        return react.createElement(react.Fragment, {}, ...xs_5);
                    }
                };
                return createElement("div", createObj(ofArray([["className", join(" ", ["pbs-tree-container"])], (elems_3 = toList(delay(() => append_1(singleton_1(moduleFilterCmp), delay(() => {
                    let elems_2;
                    let menuOrFilteredMenu;
                    const matchValue_4 = state.FilteredMenu;
                    if (matchValue_4 == null) {
                        menuOrFilteredMenu = map(createMenu, ofSeq(odc.Menu.Children));
                    }
                    else {
                        const fMenu = matchValue_4;
                        menuOrFilteredMenu = map(createMenu, ofSeq(fMenu.Children));
                    }
                    return singleton_1(createElement("nav", createObj(ofArray([["className", "pbs-tree"], (elems_2 = [createElement("ul", {
                        children: Interop_reactApi.Children.toArray(Array.from(menuOrFilteredMenu)),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
            }
            else {
                return createElement("h1", {
                    children: ["Unitialised menu"],
                });
            }
        }
        default: {
            return Render_3(new Style(0), "Loading");
        }
    }
}

