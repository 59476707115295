import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { Record } from "../../../../fable_modules/fable-library.3.7.11/Types.js";
import { Pbs$reflection } from "../../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { record_type, option_type, lambda_type, unit_type, class_type, bool_type } from "../../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { AccessToFunctions, AccessToFunctions$reflection } from "../Types.js";
import { XrmEntityReference, XrmEntityReference$reflection } from "../../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../../../../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { ofArray } from "../../../../fable_modules/fable-library.3.7.11/List.js";
import { map, defaultArg } from "../../../../fable_modules/fable-library.3.7.11/Option.js";
import { DOMAttr, HTMLAttr } from "../../../../fable_modules/Fable.React.8.0.1/Fable.React.Props.fs.js";
import { keyValueList } from "../../../../fable_modules/fable-library.3.7.11/MapUtil.js";


export class Props extends Record {
    constructor(Pbs, IsSelected, AccessToFunctions, OnPbsEdit, OnPbsEditCustomer, OnServiceDeskStart, OnPbsSelection) {
        super();
        this.Pbs = Pbs;
        this.IsSelected = IsSelected;
        this.AccessToFunctions = AccessToFunctions;
        this.OnPbsEdit = OnPbsEdit;
        this.OnPbsEditCustomer = OnPbsEditCustomer;
        this.OnServiceDeskStart = OnServiceDeskStart;
        this.OnPbsSelection = OnPbsSelection;
    }
}

export function Props$reflection() {
    return record_type("PbsRead.Props", [], Props, () => [["Pbs", Pbs$reflection()], ["IsSelected", bool_type], ["AccessToFunctions", AccessToFunctions$reflection()], ["OnPbsEdit", lambda_type(class_type("System.Guid"), unit_type)], ["OnPbsEditCustomer", lambda_type(class_type("System.Guid"), unit_type)], ["OnServiceDeskStart", option_type(lambda_type(class_type("System.Guid"), unit_type))], ["OnPbsSelection", lambda_type(XrmEntityReference$reflection(), unit_type)]]);
}

function pbsHeading(level, props) {
    switch (level) {
        case 1: {
            return createElement("h3", createObj(props));
        }
        case 2: {
            return createElement("h4", createObj(props));
        }
        case 3: {
            return createElement("h5", createObj(props));
        }
        case 4: {
            return createElement("h5", createObj(props));
        }
        default: {
            return createElement("h5", createObj(props));
        }
    }
}

function pbsEditButton(onPbsEdit, pbs) {
    const matchValue = pbs.PbsStatus;
    if (matchValue.tag === 1) {
        return createElement("button", {
            className: "btn btn-warning disabled",
            children: `${matchValue.fields[0]}`,
        });
    }
    else {
        return createElement("button", {
            className: "btn btn-primary",
            children: "Edit Pbs",
            onClick: (_arg1) => {
                onPbsEdit(pbs.Id);
            },
        });
    }
}

function pbsEditCustomerButton(onPbsEditCustomer, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Add Description",
        onClick: (_arg1) => {
            onPbsEditCustomer(pbsId);
        },
    });
}

function serviceDeskButton(onServiceDeskStart, pbsId) {
    if (onServiceDeskStart == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const osdsFn = onServiceDeskStart;
        return createElement("button", {
            className: "btn btn-primary",
            onClick: (_arg1) => {
                osdsFn(pbsId);
            },
            children: "Service Desk",
        });
    }
}

function pbsControlButtons(accessToFunctions, onPbsEdit, onPbsEditCustomer, onServiceDeskStart, pbs, exportComponent) {
    let elems, elems_1, elems_2, elems_3;
    const exportButton = exportComponent(pbs.Id);
    switch (accessToFunctions.tag) {
        case 1: {
            return createElement("div", createObj(ofArray([["className", "btn-group position-absolute top-0 end-0"], (elems = [serviceDeskButton(onServiceDeskStart, pbs.Id)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }
        case 2: {
            return createElement("div", createObj(ofArray([["className", "btn-group position-absolute top-0 end-0"], (elems_1 = [exportButton, serviceDeskButton(onServiceDeskStart, pbs.Id), pbsEditButton(onPbsEdit, pbs)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }
        case 3: {
            return createElement("div", createObj(ofArray([["className", "btn-group position-absolute top-0 end-0"], (elems_2 = [exportButton, serviceDeskButton(onServiceDeskStart, pbs.Id), pbsEditCustomerButton(onPbsEditCustomer, pbs.Id), exportComponent(pbs.Id)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }
        case 4: {
            return createElement("div", createObj(ofArray([["className", "btn-group position-absolute top-0 end-0"], (elems_3 = [exportButton, serviceDeskButton(onServiceDeskStart, pbs.Id), pbsEditButton(onPbsEdit, pbs), pbsEditCustomerButton(onPbsEditCustomer, pbs.Id)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
        }
        default: {
            return react.createElement(react.Fragment, {});
        }
    }
}

function descriptionCustomerContainer(descriptionCustomer) {
    let elems;
    if (descriptionCustomer != null) {
        const descriptionCustomer_1 = descriptionCustomer;
        return createElement("div", createObj(ofArray([["className", "description-customer-container"], (elems = [createElement("p", {
            dangerouslySetInnerHTML: {
                __html: descriptionCustomer_1,
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

function modifiedInfo(on, by) {
    const modifiedBy = defaultArg(map((txt) => txt.Name, by), "N/A");
    return createElement("label", {
        className: "modified-by-on-label",
        children: `Modified on ${on}`,
    });
}

export function Render(props, exportComponent) {
    let children_4, children, children_2, elems_1, elems;
    const props_11 = [new HTMLAttr(99, `${props.Pbs.Id}`), new DOMAttr(42, (equals(props.AccessToFunctions, new AccessToFunctions(4)) ? true : equals(props.AccessToFunctions, new AccessToFunctions(2))) ? ((_arg1) => {
        props.OnPbsEdit(props.Pbs.Id);
    }) : ((value) => {
    })), new HTMLAttr(65, (props.IsSelected === true) ? "selected-process" : "")];
    const children_6 = [(children_4 = [(children = [pbsHeading(props.Pbs.Level, ofArray([["className", "process-header"], ["children", props.Pbs.Name], ["onClick", (_arg2) => {
        props.OnPbsSelection(new XrmEntityReference(props.Pbs.Id, props.Pbs.Name));
    }]]))], react.createElement("div", {
        className: "col",
    }, ...children)), (children_2 = [pbsControlButtons(props.AccessToFunctions, props.OnPbsEdit, props.OnPbsEditCustomer, props.OnServiceDeskStart, props.Pbs, exportComponent)], react.createElement("div", {
        className: "col",
    }, ...children_2)), createElement("p", {
        onDoubleClick: (_arg3) => {
            props.OnPbsEdit(props.Pbs.Id);
        },
        dangerouslySetInnerHTML: {
            __html: props.Pbs.Description,
        },
    }), descriptionCustomerContainer(props.Pbs.DescriptionCustomer), createElement("div", createObj(ofArray([["className", ""], (elems_1 = [createElement("div", createObj(ofArray([["className", ""], (elems = [modifiedInfo(props.Pbs.ModifiedOn, props.Pbs.ModifiedBy)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("hr", {})], react.createElement("div", {
        className: "row position-relative",
    }, ...children_4))];
    return react.createElement("div", keyValueList(props_11, 1), ...children_6);
}

