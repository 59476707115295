import { fetchPbsChildren, fetchProject } from "./RestCookie.js";
import { loginRedirect, fetchPbsChildrenUserAgentApplication, fetchProjectUserAgentApplication } from "./RestMsal.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PbsTreeMenuMsg, PbsTreeMenuState, PbsTreeMenuExtraMsg } from "./Types.js";
import { value, map } from "../../../fable_modules/fable-library.3.7.11/Option.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { ComponentState as ComponentState_4 } from "../../Types.js";
import { filter, map as map_1, singleton, empty } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { PbsOperations_createPBSHieararchy, PbsMenuItemModule_fromPbs, PbsOperations_createMenu } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";

export function update(pcio, msg, state) {
    let matchValue_2, tupledArg_1, tupledArg;
    let fetchProjectFunction;
    if (pcio == null) {
        fetchProjectFunction = ((address_1) => ((projectId_1) => fetchProject(address_1, projectId_1)));
    }
    else {
        const pci = pcio;
        fetchProjectFunction = ((address) => ((projectId) => fetchProjectUserAgentApplication(pci, address, projectId)));
    }
    let fetchPbsChildrenFunction;
    if (pcio == null) {
        fetchPbsChildrenFunction = ((address_3) => ((projectId_3) => fetchPbsChildren(address_3, projectId_3)));
    }
    else {
        const pci_1 = pcio;
        fetchPbsChildrenFunction = ((address_2) => ((projectId_2) => fetchPbsChildrenUserAgentApplication(pci_1, address_2, projectId_2)));
    }
    const loginRedirectFn = (pcio == null) ? Cmd_none() : loginRedirect(pcio);
    switch (msg.tag) {
        case 1: {
            return [state, Cmd_none(), new PbsTreeMenuExtraMsg(0)];
        }
        case 2: {
            const projectId_4 = msg.fields[0];
            const selectedPbsReference = map((id) => (new XrmEntityReference(id, "")), msg.fields[1]);
            const matchValue = state.SelectedProjectId;
            if (matchValue == null) {
                return [new PbsTreeMenuState(new ComponentState_4(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_4, ""), selectedPbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchProjectFunction(state.PbsMenuRestAddresses.FetchProjectAddress)(projectId_4), new PbsTreeMenuExtraMsg(0)];
            }
            else if (matchValue.Id === projectId_4) {
                return [state, empty(), new PbsTreeMenuExtraMsg(0)];
            }
            else {
                return [new PbsTreeMenuState(new ComponentState_4(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_4, ""), selectedPbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchProjectFunction(state.PbsMenuRestAddresses.FetchProjectAddress)(projectId_4), new PbsTreeMenuExtraMsg(0)];
            }
        }
        case 3: {
            return [new PbsTreeMenuState(new ComponentState_4(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, map((id_1) => (new XrmEntityReference(id_1, "")), msg.fields[1]), state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchPbsChildrenFunction(state.PbsMenuRestAddresses.FetchPbsChildrenAddress)(msg.fields[0]), new PbsTreeMenuExtraMsg(0)];
        }
        case 4: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                return [new PbsTreeMenuState(new ComponentState_4(2, singleton(result.fields[0])), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
            }
            else {
                const odc = result.fields[0];
                return [new PbsTreeMenuState(new ComponentState_4(0), state.PbsMenuRestAddresses, odc, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(4, odc)];
            }
        }
        case 5: {
            const projectReference = msg.fields[0];
            const pbsReference = msg.fields[1];
            return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, projectReference, pbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), (matchValue_2 = state.SelectedPbsId, (matchValue_2 != null) ? ((matchValue_2.Id === pbsReference.Id) ? (new PbsTreeMenuExtraMsg(0)) : ((tupledArg_1 = [projectReference, pbsReference], new PbsTreeMenuExtraMsg(1, tupledArg_1[0], tupledArg_1[1])))) : ((tupledArg = [projectReference, pbsReference], new PbsTreeMenuExtraMsg(1, tupledArg[0], tupledArg[1]))))];
        }
        case 6: {
            const serviceRef = msg.fields[0];
            const matchValue_3 = state.OnlineDocumentation;
            if (matchValue_3 != null) {
                const odc_1 = matchValue_3;
                return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, PbsOperations_createMenu(map_1(PbsMenuItemModule_fromPbs, PbsOperations_createPBSHieararchy(odc_1.Pbses, filter((pbs_1) => (value(pbs_1.ProjectServiceModule).Id === serviceRef.Id), filter((pbs) => (pbs.ProjectServiceModule != null), odc_1.Pbses)))))), empty(), new PbsTreeMenuExtraMsg(2, serviceRef)];
            }
            else {
                return [state, empty(), new PbsTreeMenuExtraMsg(2, serviceRef)];
            }
        }
        case 7: {
            const serviceModuleRef = msg.fields[0];
            const matchValue_4 = state.OnlineDocumentation;
            if (matchValue_4 != null) {
                const odc_2 = matchValue_4;
                return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, PbsOperations_createMenu(map_1(PbsMenuItemModule_fromPbs, PbsOperations_createPBSHieararchy(odc_2.Pbses, filter((pbs_4) => (value(pbs_4.ProjectServiceModule).Id === serviceModuleRef.Id), filter((pbs_3) => (pbs_3.ProjectServiceModule != null), odc_2.Pbses)))))), empty(), new PbsTreeMenuExtraMsg(3, serviceModuleRef)];
            }
            else {
                return [state, empty(), new PbsTreeMenuExtraMsg(3, serviceModuleRef)];
            }
        }
        case 8: {
            return [state, empty(), new PbsTreeMenuExtraMsg(5)];
        }
        case 9: {
            const exn = msg.fields[0];
            const matchValue_5 = exn.message;
            if (matchValue_5.indexOf("no_account_error") >= 0) {
                if (pcio == null) {
                    return [new PbsTreeMenuState(new ComponentState_4(2, singleton(exn.message)), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
                }
                else {
                    const ua = pcio;
                    return [state, singleton((dispatch) => {
                        dispatch(new PbsTreeMenuMsg(0));
                    }), new PbsTreeMenuExtraMsg(0)];
                }
            }
            else {
                return [new PbsTreeMenuState(new ComponentState_4(2, singleton(exn.message)), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
            }
        }
        default: {
            return [state, loginRedirectFn, new PbsTreeMenuExtraMsg(0)];
        }
    }
}

