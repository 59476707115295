import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { toString, Record } from "../../../../fable_modules/fable-library.3.7.11/Types.js";
import { record_type, tuple_type, lambda_type, unit_type, string_type, class_type } from "../../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { CKEditorComponent, EditorProps } from "../../CKEDITOR/View.js";
import { Interop_reactApi } from "../../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { createObj } from "../../../../fable_modules/fable-library.3.7.11/Util.js";
import { ofArray } from "../../../../fable_modules/fable-library.3.7.11/List.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

export class Props extends Record {
    constructor(Id, Name, Description, DescriptionCustomer, OnCancelEdit, OnSave, OnReset, OnDescriptionChange) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.DescriptionCustomer = DescriptionCustomer;
        this.OnCancelEdit = OnCancelEdit;
        this.OnSave = OnSave;
        this.OnReset = OnReset;
        this.OnDescriptionChange = OnDescriptionChange;
    }
}

export function Props$reflection() {
    return record_type("PbsEditCustomer.Props", [], Props, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["DescriptionCustomer", string_type], ["OnCancelEdit", lambda_type(class_type("System.Guid"), unit_type)], ["OnSave", lambda_type(class_type("System.Guid"), unit_type)], ["OnReset", lambda_type(class_type("System.Guid"), unit_type)], ["OnDescriptionChange", lambda_type(tuple_type(class_type("System.Guid"), string_type), unit_type)]]);
}

function cancetEditButton(onCancel, pbsId) {
    return createElement("button", {
        className: "btn btn-warning",
        children: "Close Without Saving",
        onClick: (_arg1) => {
            onCancel(pbsId);
        },
    });
}

function saveButton(onSave, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Save",
        onClick: (_arg1) => {
            onSave(pbsId);
        },
    });
}

function resetButton(onReset, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Delete Description",
        onClick: (_arg1) => {
            onReset(pbsId);
        },
    });
}

function ckeditorCmp(onDescriptionChange, id, description) {
    let props;
    const relatedItemId = toString(id);
    props = (new EditorProps(toString(id), "pbs_ckeditor", description, (editorData) => {
        onDescriptionChange(editorData);
    }, relatedItemId));
    return react.createElement(CKEditorComponent, props);
}

function row(elem) {
    return createElement("div", {
        className: "row",
        children: Interop_reactApi.Children.toArray([elem]),
    });
}

export function Render(props) {
    let elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", " edit-border"], (elems_2 = [createElement("div", createObj(ofArray([["className", ""], (elems_1 = [row(createElement("h3", {
        className: "process-header",
        children: props.Name,
    })), row(createElement("p", {
        className: "aimtec-description",
        dangerouslySetInnerHTML: {
            __html: props.Description,
        },
    })), ckeditorCmp((description) => {
        props.OnDescriptionChange([props.Id, props.Description]);
    }, props.Id, props.DescriptionCustomer), row(createElement("div", createObj(ofArray([["className", "btn-group"], (elems = [saveButton(props.OnSave, props.Id), resetButton(props.OnReset, props.Id), cancetEditButton(props.OnCancelEdit, props.Id)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

