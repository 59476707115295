import { Union } from "../fable_modules/fable-library.3.7.11/Types.js";
import { union_type, list_type, string_type } from "../fable_modules/fable-library.3.7.11/Reflection.js";

export class ComponentState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loaded", "Loading", "Errorneous"];
    }
}

export function ComponentState$reflection() {
    return union_type("Aimtec.FrontEndModules.ComponentState", [], ComponentState, () => [[], [], [["Item", list_type(string_type)]]]);
}

