import * as style from "../../../../../../Aimtec.Components.React/Components/D365ProjectFrame/style.scss";
import { createElement } from "react";


export function Render(renderInputProps) {
    const src = renderInputProps.src;
    return createElement("iframe", {
        className: "crm-project-iframe",
        src: src,
    });
}

