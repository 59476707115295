import { FSharpRef, Union } from "../fable-library.3.7.11/Types.js";
import { union_type, class_type } from "../fable-library.3.7.11/Reflection.js";
import { ofArray, singleton } from "../fable-library.3.7.11/List.js";
import { Cmd_batch, Cmd_map } from "../Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { ProgramModule_map } from "../Fable.Elmish.4.0.0-beta-3/program.fs.js";

export class Navigable$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Change", "UserMsg"];
    }
}

export function Navigable$1$reflection(gen0) {
    return union_type("Elmish.Navigation.Navigable`1", [gen0], Navigable$1, () => [[["Item", class_type("Browser.Types.Location", void 0, Location)]], [["Item", gen0]]]);
}

export function Navigation_modifyUrl(newUrl) {
    return singleton((_arg1) => {
        history.replaceState(void 0, "", newUrl);
    });
}

export function Navigation_newUrl(newUrl) {
    return singleton((_arg1) => {
        history.pushState(void 0, "", newUrl);
        const ev = new CustomEvent("NavigatedEvent");
        window.dispatchEvent(ev);
    });
}

export function Navigation_jump(n) {
    return singleton((_arg1) => {
        history.go(n);
    });
}

export function ProgramModule_toNavigable(parser, urlUpdate, program) {
    const onChangeRef = new FSharpRef((_arg1) => {
        throw (new Error("`onChangeRef` has not been initialized.\nThis should not happen, please open an issue if the problem persist"));
    });
    const map = (tupledArg) => [tupledArg[0], Cmd_map((arg0) => (new Navigable$1(1, arg0)), tupledArg[1])];
    return ProgramModule_map((userInit, unitVar1) => map(userInit(parser(window.location))), (userUpdate, msg, model_1) => map((msg.tag === 1) ? userUpdate(msg.fields[0])(model_1) : urlUpdate(parser(msg.fields[0]), model_1)), (userView, model_4, dispatch_2) => userView(model_4)((arg_1) => dispatch_2(new Navigable$1(1, arg_1))), (userSetState, model_3, dispatch_1) => userSetState(model_3)((arg) => dispatch_1(new Navigable$1(1, arg))), (userSubscribe, model_2) => Cmd_batch(ofArray([singleton((dispatch) => {
        let lastLocation = void 0;
        onChangeRef.contents = ((_arg1_1) => {
            let pattern_matching_result_1;
            if (lastLocation != null) {
                if (lastLocation === window.location.href) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    break;
                }
                case 1: {
                    lastLocation = window.location.href;
                    dispatch(new Navigable$1(0, window.location));
                    break;
                }
            }
        });
        window.addEventListener("popstate", onChangeRef.contents);
        window.addEventListener("hashchange", onChangeRef.contents);
        window.addEventListener("NavigatedEvent", onChangeRef.contents);
    }), Cmd_map((arg0_1) => (new Navigable$1(1, arg0_1)), userSubscribe(model_2))])), (tupledArg_1) => [(_arg2) => {
        if (_arg2.tag === 1) {
            return tupledArg_1[0](_arg2.fields[0]);
        }
        else {
            return false;
        }
    }, (model_5) => {
        window.removeEventListener("popstate", onChangeRef.contents);
        window.removeEventListener("hashchange", onChangeRef.contents);
        window.removeEventListener("NavigatedEvent", onChangeRef.contents);
        return tupledArg_1[1](model_5);
    }], program);
}

