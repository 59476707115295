import { postCreatePbsTranslation, postUpdatePbsTranslation, postUpdatePbs, postResetCustomerDescription, postUpdatePbsCustomerDescription, fetchTranslations, fetchPbses } from "./RestCookie.js";
import { loginRedirect, postCreatePbsTranslation as postCreatePbsTranslation_1, postUpdatePbsTranslation as postUpdatePbsTranslation_1, postUpdatePbs as postUpdatePbs_1, postResetCustomerDescription as postResetCustomerDescription_1, postUpdatePbsCustomerDescription as postUpdatePbsCustomerDescription_1, fetchTranslations as fetchTranslations_1, fetchPbses as fetchPbses_1 } from "./RestMsal.js";
import { arrayHash, equalArrays, safeHash, curry, equals, uncurry } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PbsListMsg, PbsListComponentState, PbsListExtraMsg } from "./Types.js";
import { defaultArg, value as value_17, map } from "../../../fable_modules/fable-library.3.7.11/Option.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { append, find, tryFind, filter, isEmpty, map as map_1, singleton, empty } from "../../../fable_modules/fable-library.3.7.11/List.js";
import { ComponentState as ComponentState_10 } from "../../Types.js";
import { option as option_33, OptionBuilder__Return_1505, OptionBuilder__Bind_Z424BC9ED, OptionBuilder__Delay_FCFD9EF, OptionBuilder__Run_FCFD9EF } from "../../../fable_modules/FsToolkit.ErrorHandling.2.0.0/OptionCE.fs.js";
import { CreatePbsTranslationPostParams, PbsToEdit_ofPbs_Z1C2CB6B8, PbsToEdit } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { List_remove, List_add, List_replace } from "../../../AIM/Aimtec.D365.Domain/CollectionExtensions.js";
import { List_distinct } from "../../../fable_modules/fable-library.3.7.11/Seq2.js";
import { validatePbsDescription } from "./PbsListJsInterop.js";

export function update(pcio, msg, state) {
    let matchValue_2, msg_1, matchValue_3, tupledArg_1, tupledArg, msg_5, tupledArg_9, msg_7, tupledArg_17, msg_11, tupledArg_22, msg_9, tupledArg_18, msg_13, tupledArg_27;
    let fetchPbsesFunction;
    if (pcio == null) {
        fetchPbsesFunction = ((address_1) => ((projectId_1) => fetchPbses(address_1, projectId_1)));
    }
    else {
        const pci = pcio;
        fetchPbsesFunction = ((address) => ((projectId) => fetchPbses_1(pci, address, projectId)));
    }
    let fetchTranslationsFunction;
    if (pcio == null) {
        fetchTranslationsFunction = ((address_3) => ((projectId_3) => ((languageId_1) => fetchTranslations(address_3, projectId_3, languageId_1))));
    }
    else {
        const pci_1 = pcio;
        fetchTranslationsFunction = ((address_2) => ((projectId_2) => ((languageId) => fetchTranslations_1(pci_1, uncurry(2, address_2), projectId_2, languageId))));
    }
    let postUpdatePbsCustomerDescriptionFunction;
    if (pcio == null) {
        postUpdatePbsCustomerDescriptionFunction = ((address_5) => ((pbsToUpdate_1) => postUpdatePbsCustomerDescription(address_5, pbsToUpdate_1)));
    }
    else {
        const pci_2 = pcio;
        postUpdatePbsCustomerDescriptionFunction = ((address_4) => ((pbsToUpdate) => postUpdatePbsCustomerDescription_1(pci_2, address_4, pbsToUpdate)));
    }
    let postResetCustomerDescriptionFunction;
    if (pcio == null) {
        postResetCustomerDescriptionFunction = ((address_7) => ((pbsToUpdate_3) => postResetCustomerDescription(address_7, pbsToUpdate_3)));
    }
    else {
        const pci_3 = pcio;
        postResetCustomerDescriptionFunction = ((address_6) => ((pbsToUpdate_2) => postResetCustomerDescription_1(pci_3, address_6, pbsToUpdate_2)));
    }
    let postUpdatePbsFunction;
    if (pcio == null) {
        postUpdatePbsFunction = ((address_9) => ((saveAndClose_1) => ((pbsToUpdate_5) => postUpdatePbs(address_9, saveAndClose_1, pbsToUpdate_5))));
    }
    else {
        const pci_4 = pcio;
        postUpdatePbsFunction = ((address_8) => ((saveAndClose) => ((pbsToUpdate_4) => postUpdatePbs_1(pci_4, address_8, saveAndClose, pbsToUpdate_4))));
    }
    let postUpdatePbsTranslationFunction;
    if (pcio == null) {
        postUpdatePbsTranslationFunction = ((address_11) => ((saveAndClose_3) => ((pbsToUpdate_7) => postUpdatePbsTranslation(address_11, saveAndClose_3, pbsToUpdate_7))));
    }
    else {
        const pci_5 = pcio;
        postUpdatePbsTranslationFunction = ((address_10) => ((saveAndClose_2) => ((pbsToUpdate_6) => postUpdatePbsTranslation_1(pci_5, address_10, saveAndClose_2, pbsToUpdate_6))));
    }
    let postTranslationCreatePostRequestProps;
    if (pcio == null) {
        postTranslationCreatePostRequestProps = ((address_13) => ((createTranslationParams_1) => postCreatePbsTranslation(address_13, createTranslationParams_1)));
    }
    else {
        const pci_6 = pcio;
        postTranslationCreatePostRequestProps = ((address_12) => ((createTranslationParams) => postCreatePbsTranslation_1(pci_6, address_12, createTranslationParams)));
    }
    const loginRedirectFn = (pcio == null) ? Cmd_none() : loginRedirect(pcio);
    switch (msg.tag) {
        case 1: {
            return [state, Cmd_none(), new PbsListExtraMsg(0)];
        }
        case 2: {
            const projectId_4 = msg.fields[0];
            const selectedPbsReference = map((id) => (new XrmEntityReference(id, "")), msg.fields[1]);
            const matchValue = state.SelectedProjectId;
            if (matchValue == null) {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, selectedPbsReference, new XrmEntityReference(projectId_4, ""), state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchPbsesFunction(state.RestAddresses.FetchPbsesAddress)(projectId_4), new PbsListExtraMsg(0)];
            }
            else if (matchValue.Id === projectId_4) {
                return [state, empty(), new PbsListExtraMsg(0)];
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(1), state.Pbses, selectedPbsReference, new XrmEntityReference(projectId_4, ""), state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchPbsesFunction(state.RestAddresses.FetchPbsesAddress)(projectId_4), new PbsListExtraMsg(0)];
            }
        }
        case 3: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(1), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchPbsesFunction(state.RestAddresses.FetchPbsPbsesAddress)(msg.fields[0]), new PbsListExtraMsg(0)];
        }
        case 4: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(2, singleton(result.fields[0])), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(0), map_1((p) => [p, void 0], result.fields[0][0]), state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, result.fields[0][1], state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), (matchValue_2 = state.SelectedPbs, (matchValue_2 != null) ? ((msg_1 = (new PbsListMsg(6, matchValue_2.Id)), singleton((dispatch) => {
                    dispatch(msg_1);
                }))) : Cmd_none()), new PbsListExtraMsg(0)];
            }
        }
        case 5: {
            const projId = msg.fields[0];
            const pbsId = msg.fields[1];
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, pbsId, projId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), singleton((dispatch_1) => {
                dispatch_1(new PbsListMsg(6, pbsId.Id));
            }), (matchValue_3 = state.SelectedPbs, (matchValue_3 != null) ? ((matchValue_3.Id === pbsId.Id) ? (new PbsListExtraMsg(0)) : ((tupledArg_1 = [projId, pbsId], new PbsListExtraMsg(1, tupledArg_1[0], tupledArg_1[1])))) : ((tupledArg = [projId, pbsId], new PbsListExtraMsg(1, tupledArg[0], tupledArg[1]))))];
        }
        case 6: {
            const element = document.getElementById(msg.fields[0]);
            if (!equals(element, null)) {
                window.scroll(0, element.offsetTop - 0);
            }
            return [state, Cmd_none(), new PbsListExtraMsg(0)];
        }
        case 7: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(1), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchTranslationsFunction(curry(2, state.RestAddresses.FetchTranslationsAddress))(msg.fields[0])(msg.fields[1]), new PbsListExtraMsg(0)];
        }
        case 8: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 0) {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(0), result_1.fields[0][0], state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, new XrmEntityReference(result_1.fields[0][1], "fake reference"), state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(2, singleton(result_1.fields[0])), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
        }
        case 9: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, map_1((tupledArg_2) => [tupledArg_2[0], void 0], state.Pbses), state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, void 0, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 10: {
            return [new PbsListComponentState(state.RestAddresses, msg.fields[0], state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 11: {
            return [state, empty(), new PbsListExtraMsg(0)];
        }
        case 12: {
            if (isEmpty(state.Pbses)) {
                return [state, empty(), new PbsListExtraMsg(0)];
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, filter((tupledArg_4) => (value_17(tupledArg_4[0].ProjectServiceModule).Id === msg.fields[0].Id), filter((tupledArg_3) => (tupledArg_3[0].ProjectServiceModule != null), state.Pbses)), state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
            }
        }
        case 13: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, empty(), state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 24: {
            const pbsId_2 = msg.fields[0];
            return defaultArg(map((l) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs) => (pbs.Id === pbsId_2), state.PbsesEdited), (_arg1_1) => {
                const pbsToChangeName = _arg1_1;
                const changedPbs = new PbsToEdit(pbsToChangeName.Id, msg.fields[1], pbsToChangeName.Description);
                return OptionBuilder__Return_1505(option_33, List_replace((p_4) => (p_4.Id === pbsId_2), (p_5) => changedPbs, state.PbsesEdited));
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 25: {
            const pbsId_3 = msg.fields[0];
            return defaultArg(map((l_1) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_1, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_1) => (pbs_1.Id === pbsId_3), state.PbsesEdited), (_arg2_1) => {
                const pbsToChangeName_1 = _arg2_1;
                const changedPbs_1 = new PbsToEdit(pbsToChangeName_1.Id, pbsToChangeName_1.Name, msg.fields[1]);
                return OptionBuilder__Return_1505(option_33, List_replace((p_6) => (p_6.Id === pbsId_3), (p_7) => changedPbs_1, state.PbsesEdited));
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 14: {
            return defaultArg(map((l_2) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_2, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_2) => (pbs_2.Id === msg.fields[0]), map_1((tuple) => tuple[0], state.Pbses)), (_arg3) => {
                const pbs_3 = _arg3;
                return OptionBuilder__Return_1505(option_33, List_distinct(List_add(new PbsToEdit(pbs_3.Id, pbs_3.NameOnly, pbs_3.Description), state.CustomerDescriptionsEdited), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }));
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 15: {
            return defaultArg(map((l_3) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_3, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_4) => (pbs_4.Id === msg.fields[0]), state.CustomerDescriptionsEdited), (_arg4) => OptionBuilder__Return_1505(option_33, List_distinct(List_remove(_arg4, state.CustomerDescriptionsEdited), {
                Equals: equals,
                GetHashCode: safeHash,
            })))))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 16: {
            const pbsId_6 = msg.fields[0];
            return defaultArg(map((l_4) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_4, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_5) => (pbs_5.Id === pbsId_6), state.CustomerDescriptionsEdited), (_arg5) => {
                const pbsToChangeName_2 = _arg5;
                const changedPbs_2 = new PbsToEdit(pbsToChangeName_2.Id, pbsToChangeName_2.Name, msg.fields[1]);
                return OptionBuilder__Return_1505(option_33, List_replace((p_8) => (p_8.Id === pbsId_6), (p_9) => changedPbs_2, state.CustomerDescriptionsEdited));
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 17: {
            return defaultArg(map((tupledArg_5) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, tupledArg_5[0], tupledArg_5[1], state.CustomerDescriptionError), postUpdatePbsCustomerDescriptionFunction(state.RestAddresses.PostUpdatePbsCustomerDescriptionAddress)(tupledArg_5[2]), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_6) => (pbs_6.Id === msg.fields[0]), state.CustomerDescriptionsEdited), (_arg6) => {
                const pbsToEdit_2 = _arg6;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_2, state.CustomerDescriptionsEdited), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_distinct(List_add(pbsToEdit_2, state.CustomerDescriptionsSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), pbsToEdit_2]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 18: {
            const result_2 = msg.fields[0];
            if (result_2.tag === 1) {
                return [state, (msg_5 = ((tupledArg_9 = [result_2.fields[0][0], result_2.fields[0][1]], new PbsListMsg(29, tupledArg_9[0], tupledArg_9[1]))), singleton((dispatch_2) => {
                    dispatch_2(msg_5);
                })), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack = result_2.fields[0];
                return defaultArg(map((tupledArg_8) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, tupledArg_8[2], state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, tupledArg_8[0], state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_7) => (pbs_7.Id === pbsBack.Id), state.CustomerDescriptionsSaved), (_arg7) => {
                    const pbsToEdit_3 = _arg7;
                    return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_3, state.CustomerDescriptionsSaved), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    }), pbsToEdit_3, List_replace((tupledArg_6) => (tupledArg_6[0].Id === pbsBack.Id), (tupledArg_7) => [pbsBack, tupledArg_7[1]], state.Pbses)]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 19: {
            return defaultArg(map((tupledArg_10) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, tupledArg_10[0], tupledArg_10[1], state.CustomerDescriptionError), postResetCustomerDescriptionFunction(state.RestAddresses.PostResetPbsCustomerDescriptionAddress)(tupledArg_10[2]), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_8) => (pbs_8.Id === msg.fields[0]), state.CustomerDescriptionsEdited), (_arg8) => {
                const pbsToEdit_4 = _arg8;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_4, state.CustomerDescriptionsEdited), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_distinct(List_add(pbsToEdit_4, state.CustomerDescriptionsSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), pbsToEdit_4]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 22: {
            const pbsId_10 = msg.fields[0];
            let pbs_9;
            const searchResult = tryFind((tupledArg_11) => (tupledArg_11[0].Id === pbsId_10), state.Pbses);
            if (searchResult == null) {
                pbs_9 = value_17(find((translation_1) => (value_17(translation_1).Id === pbsId_10), filter((translation) => (translation != null), map_1((tuple_1) => tuple_1[1], state.Pbses))));
            }
            else {
                const t_3 = searchResult[1];
                pbs_9 = searchResult[0];
            }
            const matchValue_5 = [pbs_9.PbsStatus.tag === 0, state.AccessToFunctions];
            let pattern_matching_result;
            if (matchValue_5[0]) {
                if (matchValue_5[1].tag === 2) {
                    pattern_matching_result = 0;
                }
                else if (matchValue_5[1].tag === 4) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return defaultArg(map((l_5) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, void 0, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_5, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_10) => (pbs_10.Id === pbsId_10), append(map_1((tuple_2) => tuple_2[0], state.Pbses), map_1(value_17, filter((traO) => (traO != null), map_1((tuple_3) => tuple_3[1], state.Pbses))))), (_arg10) => {
                        const pbs_11 = _arg10;
                        return OptionBuilder__Return_1505(option_33, List_distinct(List_add(new PbsToEdit(pbs_11.Id, pbs_11.NameOnly, pbs_11.Description), state.PbsesEdited), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }));
                    })))), [state, empty(), new PbsListExtraMsg(0)]);
                }
                case 1: {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, append(state.PbsesError, singleton([PbsToEdit_ofPbs_Z1C2CB6B8(pbs_9), "Can not edit this PBS"])), state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
                }
            }
        }
        case 23: {
            return defaultArg(map((l_6) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_6, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_12) => (pbs_12.Id === msg.fields[0]), state.PbsesEdited), (_arg11) => OptionBuilder__Return_1505(option_33, List_distinct(List_remove(_arg11, state.PbsesEdited), {
                Equals: equals,
                GetHashCode: safeHash,
            })))))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 26: {
            return [state, postUpdatePbsFunction(state.RestAddresses.PostUpdatePbsAddress)(msg.fields[0])(msg.fields[1]), new PbsListExtraMsg(0)];
        }
        case 27: {
            return defaultArg(map((tupledArg_12) => {
                let tupledArg_13;
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, tupledArg_12[0], tupledArg_12[1], state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), (tupledArg_13 = [msg.fields[0], tupledArg_12[2]], validatePbsDescription(tupledArg_13[0], tupledArg_13[1])), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_13) => (pbs_13.Id === msg.fields[1]), state.PbsesEdited), (_arg12) => {
                const pbsToEdit_8 = _arg12;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_8, state.PbsesEdited), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_distinct(List_add(pbsToEdit_8, state.PbsesSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), pbsToEdit_8]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 28: {
            const result_3 = msg.fields[1];
            if (result_3.tag === 1) {
                return [state, (msg_7 = ((tupledArg_17 = [result_3.fields[0][0], result_3.fields[0][1]], new PbsListMsg(29, tupledArg_17[0], tupledArg_17[1]))), singleton((dispatch_3) => {
                    dispatch_3(msg_7);
                })), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack_1 = result_3.fields[0];
                return defaultArg(map((tupledArg_16) => {
                    const sl_4 = tupledArg_16[0];
                    const rp_1 = tupledArg_16[2];
                    if (msg.fields[0]) {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, sl_4, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                    else {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, append(state.PbsesEdited, singleton(tupledArg_16[1])), sl_4, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_14) => (pbs_14.Id === pbsBack_1.Id), state.PbsesSaved), (_arg13) => {
                    const pbsToEdit_9 = _arg13;
                    return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_9, state.PbsesSaved), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    }), pbsToEdit_9, List_replace((tupledArg_14) => (tupledArg_14[0].Id === pbsBack_1.Id), (tupledArg_15) => [pbsBack_1, tupledArg_15[1]], state.Pbses)]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 31: {
            const pbsId_14 = msg.fields[1];
            const postProps = new CreatePbsTranslationPostParams(msg.fields[0], pbsId_14, msg.fields[2]);
            const pbsToSave = find((p_23) => (p_23.Id === pbsId_14), map_1(PbsToEdit_ofPbs_Z1C2CB6B8, map_1((tuple_4) => tuple_4[0], state.Pbses)));
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, List_remove(pbsToSave, state.PbsesEdited), append(state.PbsesSaved, singleton(pbsToSave)), state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), postTranslationCreatePostRequestProps(state.RestAddresses.PostCreatePbsTranslationAddress)(postProps), new PbsListExtraMsg(0)];
        }
        case 32: {
            const result_4 = msg.fields[0];
            if (result_4.tag === 0) {
                const parentPbsId_1 = result_4.fields[0][2];
                const tupleToUpdate = find((tupledArg_19) => (tupledArg_19[0].Id === parentPbsId_1), state.Pbses);
                const updatedPbs = PbsToEdit_ofPbs_Z1C2CB6B8(tupleToUpdate[0]);
                const updatedPbses_1 = List_replace((tupledArg_20) => (tupledArg_20[0].Id === parentPbsId_1), (tupledArg_21) => [tupleToUpdate[0], result_4.fields[0][0]], state.Pbses);
                const upadtedPbsesBeingSaved = List_remove(updatedPbs, state.PbsesSaved);
                const updatedPbsesEdited_1 = append(state.PbsesEdited, singleton(updatedPbs));
                if (state.SelectedLanguage == null) {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, updatedPbses_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, updatedPbsesEdited_1, upadtedPbsesBeingSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), (msg_11 = ((tupledArg_22 = [result_4.fields[0][1], result_4.fields[0][3]], new PbsListMsg(7, tupledArg_22[0], tupledArg_22[1]))), singleton((dispatch_5) => {
                        dispatch_5(msg_11);
                    })), new PbsListExtraMsg(0)];
                }
                else {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, updatedPbses_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, updatedPbsesEdited_1, upadtedPbsesBeingSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
                }
            }
            else {
                return [state, (msg_9 = ((tupledArg_18 = [result_4.fields[0][0], result_4.fields[0][1]], new PbsListMsg(29, tupledArg_18[0], tupledArg_18[1]))), singleton((dispatch_4) => {
                    dispatch_4(msg_9);
                })), new PbsListExtraMsg(0)];
            }
        }
        case 33: {
            return defaultArg(map((tupledArg_23) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, tupledArg_23[0], tupledArg_23[1], state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), postUpdatePbsTranslationFunction(state.RestAddresses.PostUpdatePbsTranslationAddress)(msg.fields[0])(tupledArg_23[2]), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_15) => (pbs_15.Id === msg.fields[1]), state.PbsesEdited), (_arg14) => {
                const pbsToEdit_10 = _arg14;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_10, state.PbsesEdited), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_distinct(List_add(pbsToEdit_10, state.PbsesSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), pbsToEdit_10]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 34: {
            const result_5 = msg.fields[1];
            if (result_5.tag === 1) {
                return [state, (msg_13 = ((tupledArg_27 = [result_5.fields[0][0], result_5.fields[0][1]], new PbsListMsg(29, tupledArg_27[0], tupledArg_27[1]))), singleton((dispatch_6) => {
                    dispatch_6(msg_13);
                })), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack_2 = result_5.fields[0];
                return defaultArg(map((tupledArg_26) => {
                    const sl_6 = tupledArg_26[0];
                    const rp_2 = tupledArg_26[2];
                    if (msg.fields[0]) {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_2, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, sl_6, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                    else {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_2, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, append(state.PbsesEdited, singleton(tupledArg_26[1])), sl_6, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_16) => (pbs_16.Id === pbsBack_2.Id), state.PbsesSaved), (_arg15) => {
                    const pbsToEdit_11 = _arg15;
                    return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_11, state.PbsesSaved), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    }), pbsToEdit_11, List_replace((tupledArg_24) => (value_17(tupledArg_24[1]).Id === pbsBack_2.Id), (tupledArg_25) => [tupledArg_25[0], pbsBack_2], state.Pbses)]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 36: {
            const exn = msg.fields[0];
            const matchValue_7 = exn.message;
            if (matchValue_7.indexOf("no_account_error") >= 0) {
                if (pcio == null) {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(2, singleton(exn.message)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
                }
                else {
                    const ua = pcio;
                    return [state, singleton((dispatch_7) => {
                        dispatch_7(new PbsListMsg(0));
                    }), new PbsListExtraMsg(0)];
                }
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(2, singleton(exn.message)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
        }
        case 29: {
            return defaultArg(map((tupledArg_28) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, tupledArg_28[0], tupledArg_28[1], state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_17) => (pbs_17.Id === msg.fields[0]), state.PbsesSaved), (_arg16) => {
                const pbsToEdit_12 = _arg16;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_12, state.PbsesSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_add([pbsToEdit_12, msg.fields[1]], state.PbsesError), pbsToEdit_12]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 20: {
            return defaultArg(map((tupledArg_29) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, tupledArg_29[0], tupledArg_29[1]), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_18) => (pbs_18.Id === msg.fields[0]), state.CustomerDescriptionsSaved), (_arg17) => {
                const pbsToEdit_13 = _arg17;
                return OptionBuilder__Return_1505(option_33, [List_distinct(List_remove(pbsToEdit_13, state.CustomerDescriptionsSaved), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), List_add([pbsToEdit_13, msg.fields[1]], state.CustomerDescriptionError), pbsToEdit_13]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 30: {
            const pbsId_20 = msg.fields[0];
            const pbsToRemove = find((tupledArg_30) => (tupledArg_30[0].Id === pbsId_20), state.PbsesError);
            const pbsesError_2 = List_distinct(List_remove(pbsToRemove, state.PbsesError), {
                Equals: equalArrays,
                GetHashCode: arrayHash,
            });
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, (find((tupledArg_31) => (tupledArg_31[0].Id === pbsId_20), state.Pbses)[0].PbsStatus.tag === 0) ? List_add(pbsToRemove[0], state.PbsesEdited) : empty(), state.PbsesSaved, pbsesError_2, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 21: {
            const pbsToRemove_1 = find((tupledArg_32) => (tupledArg_32[0].Id === msg.fields[0]), state.CustomerDescriptionError);
            const customerDescriptionError = List_distinct(List_remove(pbsToRemove_1, state.CustomerDescriptionError), {
                Equals: equalArrays,
                GetHashCode: arrayHash,
            });
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, List_add(pbsToRemove_1[0], state.CustomerDescriptionsEdited), state.CustomerDescriptionsSaved, customerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 35: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_10(2, singleton(msg.fields[0])), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
        }
        default: {
            return [state, loginRedirectFn, new PbsListExtraMsg(0)];
        }
    }
}

