import { validateDescription as validateDescription_1 } from "../../../../../../Aimtec.Components.React/Components/PbsList/PbsListJsInterop.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.0.0-beta-3/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { PbsToEdit } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { PbsListMsg } from "./Types.js";

const validateDescription = validateDescription_1;

export function validatePbsDescription(saveAndClose, pbsToValidate) {
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (validateDescription(pbsToValidate.Description).then((_arg1) => (Promise.resolve(new PbsToEdit(pbsToValidate.Id, pbsToValidate.Name, _arg1))))))), void 0, (validatedPbs) => {
        const tupledArg = [saveAndClose, validatedPbs];
        return new PbsListMsg(26, tupledArg[0], tupledArg[1]);
    }, (error) => {
        const tupledArg_1 = [pbsToValidate.Id, error.message];
        return new PbsListMsg(29, tupledArg_1[0], tupledArg_1[1]);
    });
}

