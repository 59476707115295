import { empty, tail, head, isEmpty, append, getSlice, tryFindIndex, cons } from "../../fable_modules/fable-library.3.7.11/List.js";
import { equals } from "../../fable_modules/fable-library.3.7.11/Util.js";
import { tryFindIndex as tryFindIndex_1, append as append_1 } from "../../fable_modules/fable-library.3.7.11/Array.js";

export function List_add(element, listan) {
    return cons(element, listan);
}

export function List_remove(x, xs) {
    const matchValue = tryFindIndex((y) => equals(x, y), xs);
    if (matchValue == null) {
        return xs;
    }
    else if (matchValue === 0) {
        return getSlice(1, void 0, xs);
    }
    else {
        const i = matchValue | 0;
        return append(getSlice(void 0, i - 1, xs), getSlice(i + 1, void 0, xs));
    }
}

export function List_replace(f, sub, xs) {
    const finish = (acc_mut, _arg1_mut) => {
        finish:
        while (true) {
            const acc = acc_mut, _arg1 = _arg1_mut;
            if (!isEmpty(_arg1)) {
                acc_mut = cons(head(_arg1), acc);
                _arg1_mut = tail(_arg1);
                continue finish;
            }
            else {
                return acc;
            }
            break;
        }
    };
    const search = (acc_1_mut, _arg2_mut) => {
        search:
        while (true) {
            const acc_1 = acc_1_mut, _arg2 = _arg2_mut;
            if (!isEmpty(_arg2)) {
                const xs_2 = tail(_arg2);
                const x_1 = head(_arg2);
                if (f(x_1)) {
                    return finish(cons(sub(x_1), xs_2), acc_1);
                }
                else {
                    acc_1_mut = cons(x_1, acc_1);
                    _arg2_mut = xs_2;
                    continue search;
                }
            }
            else {
                throw (new Error("replace failed!"));
            }
            break;
        }
    };
    return search(empty(), xs);
}

export function List_tryReplace(f, sub, xs) {
    const finish = (acc_mut, _arg1_mut) => {
        finish:
        while (true) {
            const acc = acc_mut, _arg1 = _arg1_mut;
            if (!isEmpty(_arg1)) {
                acc_mut = cons(head(_arg1), acc);
                _arg1_mut = tail(_arg1);
                continue finish;
            }
            else {
                return acc;
            }
            break;
        }
    };
    const search = (acc_1_mut, _arg2_mut) => {
        search:
        while (true) {
            const acc_1 = acc_1_mut, _arg2 = _arg2_mut;
            if (!isEmpty(_arg2)) {
                const xs_2 = tail(_arg2);
                const x_1 = head(_arg2);
                if (f(x_1)) {
                    return finish(cons(sub(x_1), xs_2), acc_1);
                }
                else {
                    acc_1_mut = cons(x_1, acc_1);
                    _arg2_mut = xs_2;
                    continue search;
                }
            }
            else {
                return void 0;
            }
            break;
        }
    };
    return search(empty(), xs);
}

export function Array_add(arr, element) {
    return append_1([element], arr);
}

export function Array_remove(x, xs) {
    const matchValue = tryFindIndex_1((y) => equals(x, y), xs);
    if (matchValue == null) {
        return xs;
    }
    else if (matchValue === 0) {
        return xs.slice(1, xs.length);
    }
    else {
        const i = matchValue | 0;
        return append_1(xs.slice(void 0, (i - 1) + 1), xs.slice(i + 1, xs.length));
    }
}

