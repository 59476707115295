import { Union } from "../../../fable_modules/fable-library.3.7.11/Types.js";
import { union_type } from "../../../fable_modules/fable-library.3.7.11/Reflection.js";
import { createElement } from "react";
import { createObj } from "../../../fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { ofArray } from "../../../fable_modules/fable-library.3.7.11/List.js";

export class Style extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Primary", "Secondary", "Success", "Danger", "Warning", "Info", "Light", "Dark"];
    }
}

export function Style$reflection() {
    return union_type("Preloader.Style", [], Style, () => [[], [], [], [], [], [], [], []]);
}

function cssClass(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "alert alert-secondary";
        }
        case 2: {
            return "alert alert-success";
        }
        case 3: {
            return "alert alert-danger";
        }
        case 4: {
            return "alert alert-warning";
        }
        case 5: {
            return "alert alert-info";
        }
        case 6: {
            return "alert alert-light";
        }
        case 7: {
            return "alert alert-dark";
        }
        default: {
            return "alert alert-primary";
        }
    }
}

export function Render(preloaderStyle, message) {
    let elems;
    return createElement("div", createObj(ofArray([["className", cssClass(preloaderStyle)], (elems = [createElement("label", {
        children: message,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

