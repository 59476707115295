
export const AppConfig_appVersion = "Target Concept Editor v.2022.03.15.00";

export const AppConfig_apiaddrr = "https://stgapi.aimtec.cz/api/";

export const AzureAdConfig_clientId = "77453588-0249-41c0-bdf7-6f90c64ff79e";

export const AzureAdConfig_authority = "https://login.microsoftonline.com/b3811028-ce6e-4b01-bcb0-db419328ffc5";

export const AzureAdConfig_replyUrl = "https://iis-stg-docs-tgc-appservice.azurewebsites.net";

const WordExportApiAddress_apiAddress = AppConfig_apiaddrr;

export function WordExportApiAddress_fetchProjectExportInfo(projectId) {
    return WordExportApiAddress_apiAddress + (`WordExportJwt/ProjectExportInfo/${projectId}`);
}

export function WordExportApiAddress_fetchExportAddress() {
    return WordExportApiAddress_apiAddress + "WordExportJwt/GetProjectTargetConceptWord";
}

export function WordExportApiAddress_downloadExportAddress(fileId) {
    return WordExportApiAddress_apiAddress + (`WordExportJwt/Download/${fileId}`);
}

const ProjectApiAddress_apiAddress = AppConfig_apiaddrr;

export function ProjectApiAddress_fetchProjectInfoesAddress() {
    return ProjectApiAddress_apiAddress + "ProjectJwt/ProjectInfoes";
}

const PbsListApiAddress_apiAddress = AppConfig_apiaddrr;

export function PbsListApiAddress_fetchPbsesAddress(projectId) {
    return PbsListApiAddress_apiAddress + (`PbsJwt/ProjectPbses/${projectId}`);
}

export function PbsListApiAddress_fetchPbsPbsesAddress(pbsId) {
    return PbsListApiAddress_apiAddress + (`PbsJwt/PbsPbses/${pbsId}`);
}

export function PbsListApiAddress_fetchPbsTranslationsAddress(projectId, languageId) {
    return PbsListApiAddress_apiAddress + (`PbsJwt/ProjectTranslations/${projectId}/${languageId}`);
}

export function PbsListApiAddress_postCreatePbsTranslationAddress() {
    return PbsListApiAddress_apiAddress + "PbsJwt/CreateTranslation";
}

export function PbsListApiAddress_postUpdatePbsAddress() {
    return PbsListApiAddress_apiAddress + "PbsJwt/EditPbs";
}

export function PbsListApiAddress_postUpdatePbsTranslationAddress() {
    return PbsListApiAddress_apiAddress + "PbsJwt/EditPbsTranslation";
}

export function PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress() {
    return PbsListApiAddress_apiAddress + "PbsJwt/EditDescriptionCustomer";
}

export function PbsListApiAddress_postResetPbsCustomerDescriptionAddress() {
    return PbsListApiAddress_apiAddress + "PbsJwt/ResetDescriptionCustomer";
}

const PbsMenuApiAddress_apiAddress = AppConfig_apiaddrr;

export function PbsMenuApiAddress_fetchProjectAddress(projectId) {
    return PbsMenuApiAddress_apiAddress + (`ProjectJwt/Project/${projectId}`);
}

export function PbsMenuApiAddress_fetchPbsChildrenAddress(pbsId) {
    return PbsMenuApiAddress_apiAddress + (`ProjectJwt/PbsChildren/${pbsId}`);
}

