import { createPublicClientApplication } from "../../../../../../../Fable/Fable.Msal/Fable.Msal.js";
import { class_type } from "../../fable_modules/fable-library.3.7.11/Reflection.js";
import { RedirectRequest, RedirectRequestModule_empty, BrowserAuthOptions, Configuration, BrowserAuthOptionsModule_empty, SilentRequest, SilentRequestModule_empty } from "./Fable.Msal.Types.js";

export const Msal_createPublicClientApplication = createPublicClientApplication;

export class MsalBuilders_SilentRequestBuilder {
    constructor() {
    }
}

export function MsalBuilders_SilentRequestBuilder$reflection() {
    return class_type("Fable.Msal.MsalBuilders.SilentRequestBuilder", void 0, MsalBuilders_SilentRequestBuilder);
}

export function MsalBuilders_SilentRequestBuilder_$ctor() {
    return new MsalBuilders_SilentRequestBuilder();
}

export function MsalBuilders_SilentRequestBuilder__Yield_1505(_, _arg1) {
    return SilentRequestModule_empty();
}

export function MsalBuilders_SilentRequestBuilder__Account_31E51057(_, state, v) {
    return new SilentRequest(v, state.scopes, state.authority, state.correlationId, state.forceRefresh, state.extraQueryParameters);
}

export function MsalBuilders_SilentRequestBuilder__Scopes_Z1696520D(_, state, v) {
    return new SilentRequest(state.account, v, state.authority, state.correlationId, state.forceRefresh, state.extraQueryParameters);
}

export function MsalBuilders_SilentRequestBuilder__Authority_77BE1F39(_, state, v) {
    return new SilentRequest(state.account, state.scopes, v, state.correlationId, state.forceRefresh, state.extraQueryParameters);
}

export function MsalBuilders_SilentRequestBuilder__CorrelationId_Z21E859ED(_, state, v) {
    return new SilentRequest(state.account, state.scopes, state.authority, v, state.forceRefresh, state.extraQueryParameters);
}

export function MsalBuilders_SilentRequestBuilder__ForceRefresh_1A1E51E8(_, state, v) {
    return new SilentRequest(state.account, state.scopes, state.authority, state.correlationId, v, state.extraQueryParameters);
}

export const MsalBuilders_silentRequest = MsalBuilders_SilentRequestBuilder_$ctor();

export class MsalBuilders_ConfigurationBuilder {
    constructor() {
    }
}

export function MsalBuilders_ConfigurationBuilder$reflection() {
    return class_type("Fable.Msal.MsalBuilders.ConfigurationBuilder", void 0, MsalBuilders_ConfigurationBuilder);
}

export function MsalBuilders_ConfigurationBuilder_$ctor() {
    return new MsalBuilders_ConfigurationBuilder();
}

export function MsalBuilders_ConfigurationBuilder__Yield_1505(_, _arg1) {
    return new Configuration(BrowserAuthOptionsModule_empty(), void 0);
}

export function MsalBuilders_ConfigurationBuilder__Auth_27E9ADC4(_, state, auth) {
    return new Configuration(auth, state.cache);
}

export const MsalBuilders_configuration = MsalBuilders_ConfigurationBuilder_$ctor();

export class MsalBuilders_BrowserAuthOptionsBuilder {
    constructor() {
    }
}

export function MsalBuilders_BrowserAuthOptionsBuilder$reflection() {
    return class_type("Fable.Msal.MsalBuilders.BrowserAuthOptionsBuilder", void 0, MsalBuilders_BrowserAuthOptionsBuilder);
}

export function MsalBuilders_BrowserAuthOptionsBuilder_$ctor() {
    return new MsalBuilders_BrowserAuthOptionsBuilder();
}

export function MsalBuilders_BrowserAuthOptionsBuilder__Yield_1505(_, _arg1) {
    return BrowserAuthOptionsModule_empty();
}

export function MsalBuilders_BrowserAuthOptionsBuilder__ClientId_33743E55(_, state, clientId) {
    return new BrowserAuthOptions(clientId, state.authority, state.redirectUri);
}

export function MsalBuilders_BrowserAuthOptionsBuilder__Authority_33743E55(_, state, authority) {
    return new BrowserAuthOptions(state.clientId, authority, state.redirectUri);
}

export function MsalBuilders_BrowserAuthOptionsBuilder__RedirectUri_33743E55(_, state, redirectUri) {
    return new BrowserAuthOptions(state.clientId, state.authority, redirectUri);
}

export const MsalBuilders_browserAuthOptions = MsalBuilders_BrowserAuthOptionsBuilder_$ctor();

export class MsalBuilders_RedirectRequestBuilder {
    constructor() {
    }
}

export function MsalBuilders_RedirectRequestBuilder$reflection() {
    return class_type("Fable.Msal.MsalBuilders.RedirectRequestBuilder", void 0, MsalBuilders_RedirectRequestBuilder);
}

export function MsalBuilders_RedirectRequestBuilder_$ctor() {
    return new MsalBuilders_RedirectRequestBuilder();
}

export function MsalBuilders_RedirectRequestBuilder__Yield_1505(_, _arg1) {
    return RedirectRequestModule_empty();
}

export function MsalBuilders_RedirectRequestBuilder__Prompt_644202EA(_, state, prompt) {
    return new RedirectRequest(prompt, state.scopes);
}

export function MsalBuilders_RedirectRequestBuilder__Scopes_Z56A4FE0(_, state, scopes) {
    return new RedirectRequest(state.prompt, scopes);
}

export const MsalBuilders_redirectRequest = MsalBuilders_RedirectRequestBuilder_$ctor();

