import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { createElement } from "react";
import { createObj } from "../../../../fable_modules/fable-library.3.7.11/Util.js";
import { Style, Render as Render_1 } from "../../Preloader/Preloader.js";
import { Interop_reactApi } from "../../../../fable_modules/Feliz.1.65.0/Interop.fs.js";
import { ofArray } from "../../../../fable_modules/fable-library.3.7.11/List.js";


export const Render = createElement("div", createObj(ofArray([["className", "preloader-container"], (() => {
    const elems = [Render_1(new Style(5), "Saving")];
    return ["children", Interop_reactApi.Children.toArray(Array.from(elems))];
})()])));

